import Model from './interface/Model'
import { randomUUID } from './interface/Common'

export default class DistributionGoal extends Model {
  public id: any = randomUUID()

  public target_adunits: any = []

  public mode: any = 'percentage'

  public value: any = 0
}
