import SelectOption from '@/models/interface/SelectOption'

export default {
  buffer_rules: [
    {
      name: 'None',
      value: 'none',
    },
    {
      name: 'Fixed',
      value: 'fixed',
    },
    {
      name: 'Percentage',
      value: 'percentage',
    },
  ],

  discount_types: [
    {
      name: 'Percentage',
      value: 'percentage',
    },
    {
      name: 'Absolute value',
      value: 'absolute_value',
    },
  ],

  line_item_types: [
    {
      name: 'Sponsorship (4)',
      value: 'sponsorship',
      message:
        'Sponsorship is the highest-ranking line item type for fixed-position and time-based campaigns.',
      priority_value: 4,
      rules: 'required|min_value:1|max_value:16',
    },
    {
      name: 'Standard (6, 8, 10)',
      value: 'standard',
      message: 'For impression-based campaigns.',
      priority_value: 8,
      rules: 'required|min_value:5|max_value:16',
    },
    {
      name: 'Backfill (12, 13, 14)',
      value: 'backfill',
      message: 'For backfill inventory.',
      priority_value: 13,
      rules: 'required|min_value:5|max_value:16',
    },
  ],

  priority_list: [
    {
      name: 'High',
      value: 'high',
    },
    {
      name: 'Normal',
      value: 'normal',
    },
    {
      name: 'Low',
      value: 'low',
    },
  ],

  creative_sizes: [
    {
      name: '400x300v(Video / VAST)',
      value: '400x300',
    },
    {
      name: '640x480v(Video / VAST)',
      value: '400x300',
    },
  ],
  pods: [
    new SelectOption('Position 1', 1),
    new SelectOption('Position 2', 2),
    new SelectOption('Position 3', 3),
    new SelectOption('Position 4', 4),
    new SelectOption('Position 5', 5),
    new SelectOption('Position 6', 6),
    new SelectOption('Position 7', 7),
    new SelectOption('Position 8', 8),
    new SelectOption('Position 9', 9),
  ],
  companion_sizes: [
    { name: 'Out-of-page (Interstitial)', value: 'out-of-page' },
    { name: '4:1 (Aspect ratio 4:1)', value: '4:1' },
    { name: '6:1 (Aspect ratio 6:1)', value: '6:1' },
    { name: '88x31 (Micro Bar)', value: '88x31' },
    { name: '120x20 (MMA Small Banner)', value: '120x20' },
    { name: '120x30 (MMA Small Banner)', value: '120x30' },
    { name: '120x60 (Button 2)', value: '120x60' },
    { name: '120x90 (Button 1)', value: '120x90' },
    { name: '120x240 (Vertical Banner)', value: '120x240' },
    { name: '120x600 (Skyscraper)', value: '120x600' },
    { name: '125x125 (Button)', value: '125x125' },
    { name: '160x600 (Wide Skyscraper)', value: '160x600' },
    { name: '168x28 (MMA Medium Banner)', value: '168x28' },
    { name: '168x42 (MMA Medium Banner)', value: '168x42' },
    { name: '180x150 (Rectangle)', value: '180x150' },
    { name: '200x200 (Small Square)', value: '200x200' },
    { name: '200x446 (Custom)', value: '200x446' },
    { name: '216x36 (MMA Large Banner)', value: '216x36' },
    { name: '216x54 (MMA Large Banner)', value: '216x54' },
    { name: '220x90 (Custom)', value: '220x90' },
    { name: '234x60 (Half Banner)', value: '234x60' },
    { name: '240x133 (Custom)', value: '240x133' },
    { name: '240x400 (Vertical Rectangle)', value: '240x400' },
    { name: '250x250 (Square)', value: '250x250' },
    { name: '250x360 (Custom)', value: '250x360' },
    { name: '292x30 (Custom)', value: '292x30' },
    { name: '300x31 (Custom)', value: '300x31' },
    { name: '300x50 (MMA Extra Large Banner)', value: '300x50' },
    { name: '300x75 (MMA Extra Large Banner)', value: '300x75' },
    { name: '300x100 (3:1 Rectangle)', value: '300x100' },
    { name: '300x250 (Medium Rectangle)', value: '300x250' },
    { name: '300x600 (Half Page Ad)', value: '300x600' },
    { name: '300x1050 (Custom)', value: '300x1050' },
    { name: '320x50 (Mobile Leaderboard)', value: '320x50' },
    { name: '320x100 (Custom)', value: '320x100' },
    { name: '320x480 (Mobile Portrait Full Screen)', value: '320x480' },
    { name: '336x280 (Large Rectangle)', value: '336x280' },
    { name: '468x60 (Full Banner)', value: '468x60' },
    { name: '480x320 (Mobile Landscape Full Screen)', value: '480x320' },
    { name: '580x400 (Custom)', value: '580x400' },
    { name: '728x90 (Leaderboard)', value: '728x90' },
    { name: '750x100 (Custom)', value: '750x100' },
    { name: '750x200 (Custom)', value: '750x200' },
    { name: '750x300 (Custom)', value: '750x300' },
    { name: '768x1024 (Tablet Portrait Full Screen)', value: '768x1024' },
    { name: '930x180 (Custom)', value: '930x180' },
    { name: '950x90 (Custom)', value: '950x90' },
    { name: '960x90 (Custom)', value: '960x90' },
    { name: '970x66 (Custom)', value: '970x66' },
    { name: '970x90 (Large Leaderboard)', value: '970x90' },
    { name: '970x250 (Custom)', value: '970x250' },
    { name: '980x90 (Custom)', value: '980x90' },
    { name: '980x120 (Custom)', value: '980x120' },
    { name: '1024x768 (Tablet Landscape Full Screen)', value: '1024x768' },
  ],

  native_media_orientations: [
    {
      name: 'Landscape',
      value: 'landscape',
    },
    {
      name: 'Portrait',
      value: 'portrait',
    },
    {
      name: 'Square',
      value: 'square',
    },
  ],

  display_companions_list: [
    {
      name: 'Optional',
      value: 'optional',
    },
    {
      name: 'At Least One',
      value: 'at_least_one',
    },
    {
      name: 'All',
      value: 'all',
    },
  ],

  deliver_options: [
    {
      name: 'Evenly',
      value: 'even',
    },
    {
      name: 'Frontloaded',
      value: 'frontloaded',
    },
    {
      name: 'As Fast As Possible',
      value: 'as_fast_as_possible',
    },
    {
      name: 'Evenly Strict',
      value: 'even_strict',
    },
  ],

  rotate_creative_sets: [
    {
      name: 'Evenly',
      value: 'even',
    },
    {
      name: 'Weighted',
      value: 'weighted',
    },
    {
      name: 'Sequential',
      value: 'sequential',
    },
    { name: 'Optimize VCR', value: 'optimize_vcr' },
    { name: 'Optimize CTR', value: 'optimize_ctr' },
  ],

  child_ads: [
    {
      name: "Don't serve on child-directed requests",
      value: false,
    },
    {
      name: 'Allow to serve on child-directed requests',
      value: true,
    },
  ],

  frequency_list: [
    {
      name: 'Hours',
      value: 'hour',
    },
    {
      name: 'Days',
      value: 'day',
    },
    {
      name: 'Weeks',
      value: 'week',
    },
    {
      name: 'Months',
      value: 'month',
    },
  ],

  ad_type_list: [
    {
      name: 'Video',
      value: 'video',
    },
  ],

  model_type_list: [
    {
      name: 'CPM',
      value: 'cpm',
    },
    {
      name: 'CPC',
      value: 'cpc',
    },
    {
      name: 'CPCV',
      value: 'cpcv',
    },
  ],
}
