import Creative from './Creative'
import Model from './interface/Model'

export default class AssociatedCreative extends Model {
  public weight_mode: any = 'auto'

  public weight_value: any = 0

  public sequence: any = 0

  public creative: Creative | any = {}

  public selected_option: any = null

  public status: any = 'not saved'

  public start_at: any = null

  public end_at: any = null

  public created_at: string | null = null

  public toObject(source: any): any {
    let instance = this.clone()

    Object.assign(instance, source)

    if (source.creative) {
      instance.creative = Creative.toObject(source.creative)
    }
    return instance
  }
}
