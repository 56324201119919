
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import LineItem from '@/models/LineItem'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import moment from 'moment'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'

@Component({
  components: {
    DatePicker,
    IconAction,
  },
})
export default class TimeRestriction extends ViewModel {
  @Prop()
  value!: any // LineItem

  @Prop({ default: true })
  wrapped!: boolean // LineItem

  @Prop({ default: false })
  disabled!: boolean // LineItem

  public local_line_item: LineItem = new LineItem()

  public active: boolean = false

  public weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  @Watch('local_line_item')
  public onLocalLineItemChange(val: any) {
    if (val) {
      this.$emit('input', val)
    }
  }

  @Watch('active')
  public onActiveChange(val: boolean) {
    this.local_line_item.targetting.active = val
  }

  public mounted() {
    this.loading = true
    this.local_line_item = this.value
    if (this.local_line_item.targetting.time_restrictions.length) {
      this.local_line_item.targetting.active = true
      this.active = true
    }

    this.registerWatchers()
    this.validate()

    this.loading = false
  }

  /**
   * Register watchers for each time restriction
   */
  public registerWatchers() {
    const cleanUpDays = (start: string, end: string, days: string[]) =>
      days.filter(d => this.isInRange(start, end, d))

    this.local_line_item.targetting.time_restrictions.forEach((rule: any, index: number) => {
      if (rule._watcher) {
        return
      }

      rule._watcher = this.$watch(
        () => rule.start_at,
        () => {
          if (rule.use_specific_dates && moment(rule.start_at).isAfter(rule.end_at)) {
            setTimeout(() => {
              rule.end_at = rule.start_at
              rule.days = cleanUpDays(rule.start_at, rule.end_at, rule.days)
              this.validate()
            }, 250)
          } else {
            rule.days = cleanUpDays(rule.start_at, rule.end_at, rule.days)
            this.validate()
          }
        },
      )

      rule._watcher = this.$watch(
        () => rule.end_at,
        () => {
          if (rule.use_specific_dates && moment(rule.start_at).isAfter(rule.end_at)) {
            setTimeout(() => {
              rule.end_at = rule.start_at
              rule.days = cleanUpDays(rule.start_at, rule.end_at, rule.days)
              this.validate()
            }, 250)
          } else {
            rule.days = cleanUpDays(rule.start_at, rule.end_at, rule.days)
            this.validate()
          }
        },
      )

      rule._watcher = this.$watch(
        () => rule.start_time,
        () => {
          this.validate()
        },
      )

      rule._watcher = this.$watch(
        () => rule.end_time,
        () => {
          this.validate()
        },
      )

      rule._watcher = this.$watch(
        () => rule.days,
        () => {
          this.validate()
        },
      )

      rule._watcher = this.$watch(
        () => rule.type,
        () => {
          this.validate()
        },
      )

      rule._watcher = this.$watch(
        () => rule.use_specific_dates,
        (value: boolean, old: boolean) => {
          this.validate()
        },
      )
    })
  }

  public weekDayColor(day: string, dayTime: any) {
    if (!this.isInRange(dayTime.start_at, dayTime.end_at, day)) {
      return 'light'
    }

    if (dayTime.days.includes(day)) {
      return 'primary'
    }

    return 'secondary'
  }

  public toggleSpecificDates(rule: any, e: any) {
    if (!e) {
      rule.start_at = ''
      rule.end_at = ''
    } else {
      rule.start_at = moment(this.local_line_item.start_at).format('YYYY-MM-DD')
      rule.end_at = moment(this.local_line_item.end_at).format('YYYY-MM-DD')
    }
  }

  public isInRange(start: string, end: string, day: string) {
    if (!start || !end) {
      return true
    }
    let startAt = moment(start, 'YYYY-MM-DD')
    let endAt = moment(end, 'YYYY-MM-DD')

    // If has more than 7 days, return true
    if (endAt.diff(startAt, 'days') >= 7) {
      return true
    }

    // If has less than 7 days, check if dayof week is in range
    let dayIndex = this.weekdays.indexOf(day)

    while (startAt.isSameOrBefore(endAt)) {
      if (startAt.day() == dayIndex) {
        return true
      }
      startAt.add(1, 'days')
    }

    return false
  }

  /**
   * Add new rule
   */
  public addTimePeriod() {
    const newIdx = this.local_line_item.targetting.time_restrictions.length - 1
    // @ts-ignore
    this.local_line_item.targetting.time_restrictions.push({
      id: this.randomUUID(),
      use_specific_dates: false,
      start_at: '',
      end_at: '',
      time_start_at: '19:00',
      time_end_at: '22:00',
      days: [],
      type: 'include',
      available_weekdays: [],
      has_errors: false,
      _watcher: null,
    })

    this.registerWatchers()

    this.validate()
  }

  public validate() {
    this.validateTimeRestrictionOverlap()
  }

  public selectDay(day: string, index: number) {
    let target = this.local_line_item.targetting.time_restrictions[index]
    if (!this.isInRange(target.start_at, target.end_at, day)) {
      return
    }

    let _idx = target.days.findIndex((d: any) => d === day)
    if (_idx > -1) {
      target.days.splice(_idx, 1)
    } else {
      target.days.push(day)
    }
  }

  /**
   * Check if time restriction overlaps
   */
  public validateTimeRestrictionOverlap() {
    // Clear all errors before validating it
    this.setError(
      false,
      ...this.local_line_item.targetting.time_restrictions.map((rule: any) => rule.id),
    )

    for (
      let baseIdx = 0;
      baseIdx < this.local_line_item.targetting.time_restrictions.length;
      baseIdx++
    ) {
      // Stop loop if last item
      if (baseIdx === this.local_line_item.targetting.time_restrictions.length - 1) {
        break
      }

      let baseItem = this.local_line_item.targetting.time_restrictions[baseIdx]
      for (
        let idx = baseIdx + 1;
        idx < this.local_line_item.targetting.time_restrictions.length;
        idx++
      ) {
        let rule = this.local_line_item.targetting.time_restrictions[idx]

        // Validate Overlaps
        let baseStartTime = moment(baseItem.time_start_at, 'h:mm a')
        let baseEndTime = moment(baseItem.time_end_at, 'h:mm a')
        let ruleStartTime = moment(rule.time_start_at, 'h:mm a')
        let ruleEndTime = moment(rule.time_end_at, 'h:mm a')

        let baseStartAt = moment(baseItem.start_at, 'YYYY-MM-DD')
        let baseEndAt = moment(baseItem.end_at, 'YYYY-MM-DD')
        let ruleStartAt = moment(rule.start_at, 'YYYY-MM-DD')
        let ruleEndAt = moment(rule.end_at, 'YYYY-MM-DD')

        let baseDays = baseItem.days
        let ruleDays = rule.days

        let baseUseSpecificDates = baseItem.use_specific_dates
        let ruleUseSpecificDates = rule.use_specific_dates

        // Check for time overlap
        let overlapTime = baseItem.type === 'exclude'
          || rule.type === 'exclude'
          || baseStartTime.isBetween(ruleStartTime, ruleEndTime, null, '[]')
          || baseEndTime.isBetween(ruleStartTime, ruleEndTime, null, '[]')
          || ruleStartTime.isBetween(baseStartTime, baseEndTime, null, '[]')
          || ruleEndTime.isBetween(baseStartTime, baseEndTime, null, '[]')

        // CHeck if days overlap
        let overlapDay = baseDays.some((day: any) => {
          if (ruleDays.includes(day)) {
            return true
          }
          return false
        })
        // Check if dates overlap
        let overlapDate = !baseUseSpecificDates
          || !ruleUseSpecificDates
          || baseStartAt.isBetween(ruleStartAt, ruleEndAt, null, '[]')
          || baseEndAt.isBetween(ruleStartAt, ruleEndAt, null, '[]')
          || ruleStartAt.isBetween(baseStartAt, baseEndAt, null, '[]')
          || ruleEndAt.isBetween(baseStartAt, baseEndAt, null, '[]')

        if (overlapTime && overlapDay && overlapDate) {
          this.setError(true, baseItem.id, rule.id)
        }
      }
    }
    WebMessage.hideAll()
    if (this.local_line_item.targetting.time_restrictions.some((rule: any) => rule.has_errors)) {
      WebMessage.error('Time restriction overlap detected')
    }
  }

  private setError(value: boolean, ...indexs: any) {
    this.local_line_item.targetting.time_restrictions = this.local_line_item.targetting.time_restrictions.map((item: any) => {
      if (indexs.includes(item.id)) {
        item.has_errors = value
      }
      return item
    })
  }

  public removeTimePeriod(index: any) {
    // remove watcher
    if (this.local_line_item.targetting.time_restrictions[index]._watcher) {
      this.local_line_item.targetting.time_restrictions[index]._watcher()
    }

    this.local_line_item.targetting.time_restrictions.splice(index, 1)
    this.validate()
  }
}
