
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'

@Component({
  components: {
    FormInput,
    IconAction,
  },
})
export default class DeviceCategory extends ViewModel {
  @Prop()
  value!: any

  @Prop()
  exclude!: any

  public filter: any = ''

  public selected_nodes: any = []

  public device_categories: any = [
    {
      name: 'Connected TV',
      value: 'ctv',
    },
    {
      name: 'Desktop',
      value: 'desktop',
    },
    {
      name: 'Mobile',
      value: 'mobile',
    },
  ]

  public get devices() {
    return this.device_categories.filter((d: any) => {
      if (this.filter.length) {
        return d.name.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase())
      }
      return d
    })
  }

  public mounted() {
    this.value.include_devices.forEach((d: any) => {
      this.select(this.device_categories.find((dc: any) => dc.value === d))
    })
    this.value.exclude_devices.forEach((d: any) => {
      this.select(
        this.device_categories.find((dc: any) => dc.value === d),
        false,
      )
    })
  }

  public select(node: any, include: boolean = true) {
    let index = this.selected_nodes.findIndex((s: any) => s.value === node.value)
    let _action = include ? 'include' : 'exclude'
    let obj = {
      ...node,
      action: _action,
    }

    if (index > -1) {
      Vue.set(this.selected_nodes[index], 'action', _action)
    } else {
      this.selected_nodes.push(obj)
    }

    this.emit()
  }

  public removeAll() {
    this.selected_nodes = []
    this.emit()
  }

  public remove(index: number) {
    this.selected_nodes.splice(index, 1)
    this.emit()
  }

  public isSelected(node: any) {
    return this.selected_nodes.some(sn => sn.name === node.name)
  }

  public emit() {
    setTimeout(() => {
      let include = this.selected_nodes.filter(sn => sn.action === 'include')
      let exclude = this.selected_nodes.filter(sn => sn.action === 'exclude')
      //   console.log('the exlude', exclude)
      const map_values = (arr: any) => arr.map((n: any) => n.value)

      this.$emit('input', {
        ...this.value,
        include_devices: map_values(include),
        exclude_devices: map_values(exclude),
      })
    }, 400)
  }
}
