
import ViewModel from '@/models/ViewModel'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EditableSlot extends ViewModel {
  @Prop()
  public editIconCLass!: any

  @Prop()
  public closeIconCLass!: any

  @Prop()
  public textClass!: any

  @Prop()
  public side_value!: any

  @Prop({ default: false })
  public disabled!: boolean

  public editing: boolean = false
}
