// @ts-ignore
import { Md5 } from 'ts-md5/dist/md5'
import WebMessage from './WebMessage'
import Model from './interface/Model'

export default class AuditHistory extends Model {
  public api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'audit' as string | null,
      plural: 'audits' as string | null,
    },
  }

  public auditable_id: string | null = null

  public auditable_type: string | null = null

  public created_at: string | null = null

  public event: string | null = null

  public id: string | null = null

  public ip_address: string | null = null

  public new_values: any = null

  public old_values: any = null

  public tags: any = null

  public updated_at: string | null = null

  public url: string | null = null

  public user_agent: string | null = null

  public user_id: string | null = null

  public user_type: string | null = null

  public notes: string | null = null

  public hash: string | null = null

  public toggle_notes: boolean = false

  public get has_notes() {
    return this.notes && this.notes.length ? 'Yes' : 'No'
  }

  // Check if object has changed
  public get is_dirty(): boolean {
    return this.hash !== this.checksum
  }

  public get apiData() {
    return {
      id: this.id,
      notes: this.notes,
    }
  }

  public afterSaved() {
    this.updateChecksum()
  }

  public toggleNotes() {
    this.toggle_notes = !this.toggle_notes
  }

  public onSave(response: any) {
    WebMessage.success('History saved!')
    this.afterSaved()
    return response
  }

  public get checksum() {
    return Md5.hashStr(this.notes || '')
  }

  public calculateChecksum() {
    return Md5.hashStr(this.notes || '')
  }

  public updateChecksum() {
    this.hash = this.calculateChecksum()
  }
}
