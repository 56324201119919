import SelectOption from '@/models/interface/SelectOption'
import LineItem from '@/models/LineItem'
import WebMessage from '@/models/WebMessage'
import router from '@/Routes'
import numeral from 'numeral'

const handleArchive = (line_item: LineItem, dataTable: any) => {
  let title = 'Archive Line Item'
  let message = 'Are you sure you want to archive this line item? This will automatically pause the campaign if active.'
  let okTitle = 'Archive'
  if (line_item.archived) {
    title = 'Unarchive Line Item'
    message = 'Are you sure you want to unarchive this line item? This will automatically unarchive the order (if archived).'
    okTitle = 'Unarchive'
  }
  WebMessage.confirm(message, title, { okTitle }).then((result: boolean) => {
    if (result) {
      line_item.archiveLineItem(!line_item.archived).then(() => {
        setTimeout(() => {
          dataTable.refresh()
        }, 500)
      })
    }
  })
}

export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
  },
  {
    key: 'id',
    label: 'Id',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-left align-middle',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: { 'min-width': '300px' },
  },
  {
    key: 'agency_id',
    label: 'Agency',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'advertiser_id',
    label: 'Advertiser',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },

  {
    key: 'station_id',
    label: 'Station',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'account_executive_id',
    label: 'Account Executive',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'start_at',
    label: 'Start at',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'end_at',
    label: 'End at',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    type: 'badge',
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    formatter: (status: string, _: any, line_item: LineItem) => line_item.status_name,
    color: (line_item: LineItem) => line_item.status_color,
    filter_type: 'picker',
    options: [
      new SelectOption('Status', ''),
      new SelectOption('Delivering', 'delivering'),
      new SelectOption('Paused', 'paused'),
      new SelectOption('Pending Creative', 'missing_creative'),
      new SelectOption('Ready', 'ready'),
      new SelectOption('Completed', 'completed'),
    ],
  },
  {
    key: 'targetting',
    label: '',
    sortable: false,
    filter: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    filter_type: 'picker',
    options: [
      new SelectOption('Type', ''),
      new SelectOption('Sponsorship', 'sponsorship'),
      new SelectOption('Standard', 'standard'),
      new SelectOption('Backfill', 'backfill'),
    ],
  },
  {
    key: 'priority',
    label: 'Priority',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'pacing',
    label: 'Delivery',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'capitalize_replace',
    thClass: 'align-middle text-center',
    filter_type: 'picker',
    options: [
      new SelectOption('Delivery', ''),
      new SelectOption('Even', 'even'),
      new SelectOption('As Fast As Possible', 'as_fast_as_possible'),
      new SelectOption('Frontloaded', 'frontloaded'),
    ],
  },
  {
    key: 'plan_amount',
    label: 'Contracted Amount',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center',
    formatter: (__: number, _: any, line_item: LineItem) => {
      if (line_item.media_plan_item_id && line_item.media_plan_item) {
        return line_item.media_plan_item.open_ended
          ? 'Open Ended'
          : numeral(line_item.media_plan_item.impressions).format('0,0')
      }
      if (line_item.media_plan_id && line_item.media_plan) {
        return numeral(line_item.media_plan.impressions).format('0,0')
      }
      return 'N/A'
    },
  },
  {
    key: 'order_booked',
    label: 'Order Booked',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center',
    formatter: (__: number, _: any, line_item: LineItem) =>
      (line_item.order.open_ended
        ? 'Open Ended'
        : numeral(line_item.order.contracted_amount).format('0,0')),
  },
  {
    key: 'contracted_amount',
    label: 'Booked',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    formatter: (value: number, _: any, line_item: LineItem) =>
      (line_item.open_ended ? 'Open Ended' : value.toLocaleString('en')),
  },
  {
    key: 'delivered',
    label: 'Delivered',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'pacing_progress',
    label: 'Pacing',
    sortable: true,
    // filter: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'vcr',
    label: 'VCR',
    sortable: true,
    // filter: false,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center',
    formatter: (_: number, __: any, line_item: LineItem) => {
      if (line_item.metrics && line_item.metrics.completion_rate) {
        return numeral(line_item.metrics.completion_rate).format('0.00%')
      }
      return 'N/A'
    },
  },
  {
    key: 'delivery_boost',
    label: 'Delivery Boost',
    sortable: false,
    // filter: false,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'delta_diff',
    label: 'A/F Delta',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'performance',
    label: 'Performance',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: 'min-width: 200px',
  },
  {
    key: 'item_issues',
    label: 'Issues',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'adserver',
    label: 'Adserver',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'history_notes_count',
    label: 'Audit notes',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    type: 'badge',
    color: (line_item: LineItem) => (line_item.history_notes_count == 0 ? 'info' : 'warning'),
    thClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    show: true,
    type: 'action_list',
    tdClass: 'actions',
    thStyle: { width: '160px' },
    class: 'text-center align-middle text-capitalize',
    thClass: 'align-middle text-center',
    actions: [
      {
        icon: 'eye',
        title: 'View Line Item',
        event: 'view',
        action: (lineItem: LineItem) => {
          if (lineItem.adserver === 'gam') {
            window
              .open(
                `https://admanager.google.com/21789751937#delivery/line_item/detail/line_item_id=${lineItem.admanager_id}`,
                '_blank',
              )
              ?.focus()

            return
          }
          let ref = window.location.href.includes('legacy-dashboard') ? '&ref=LegacyDashboard' : ''
          if (lineItem.id) {
            router.push({
              name: 'line-item',
              params: { id: lineItem.id },
            })
          }
        },
      },
      {
        icon: 'pencil',
        title: 'Edit Order',
        top_level: true,
        event: 'edit',
        action: (lineItem: LineItem) => {
          if (lineItem.adserver === 'gam') {
            window
              .open(
                `https://admanager.google.com/21789751937#delivery/line_item/detail/line_item_id=${lineItem.admanager_id}`,
                '_blank',
              )
              ?.focus()

            return
          }
          let ref = window.location.href.includes('legacy-dashboard') ? '?ref=LegacyDashboard' : ''
          if (lineItem.id) {
            router.push({
              name: 'line-item',
              params: { id: lineItem.id },
            })
          }
        },
      },
      {
        icon: 'history',
        title: 'View History',
        event: 'history',
        top_level: true,
      },

      {
        icon: 'play',
        title: 'Play',
        event: 'play',
        show: (lineItem: LineItem) => lineItem.status === 'paused',
        action: (lineItem: LineItem, dataTable: any) => {
          lineItem.play().then(() => {
            dataTable.refresh()
          })
        },
      },
      {
        icon: 'pause',
        title: 'Pause',
        event: 'pause',
        show: (lineItem: LineItem) => lineItem.status !== 'paused',
        action: (lineItem: LineItem, dataTable: any) => {
          lineItem.pause().then(() => {
            dataTable.refresh()
          })
        },
      },
      {
        icon: 'sync',
        title: 'Sync with Media Plan',
        show: (lineItem: LineItem) => !!lineItem.media_plan_item_id,
        event: 'sync',
        top_level: false,
      },
      {
        icon: 'clone',
        title: 'Clone',
        event: 'clone',
        action: (lineItem: LineItem) => {
          if (lineItem.id) {
            router.push({
              name: 'line-item',
              // @ts-ignore
              params: { id: lineItem.id, clone: true },
              // @ts-ignore
              query: { cloning: lineItem.name, ref: router.currentRoute.name },
            })
          }
        },
      },
      {
        icon: 'archive',
        title: 'Archive Line Item',
        event: 'archive',
        show: (line_item: LineItem) => line_item.adserver == 'cim' && !line_item.archived,
        action: (line_item: LineItem, dataTable: any) => {
          handleArchive(line_item, dataTable)
        },
      },
      {
        icon: 'archive',
        title: 'Unarchive Line Item',
        event: 'unarchive',
        show: (line_item: LineItem) => line_item.adserver == 'cim' && line_item.archived,
        action: (line_item: LineItem, dataTable: any) => {
          handleArchive(line_item, dataTable)
        },
      },
    ],
  },
]
