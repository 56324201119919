import { render, staticRenderFns } from "./AuditHistoryTable.vue?vue&type=template&id=28603fb6&scoped=true&"
import script from "./AuditHistoryTable.vue?vue&type=script&lang=ts&"
export * from "./AuditHistoryTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28603fb6",
  null
  
)

export default component.exports