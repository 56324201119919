
import {
  Component, Prop, Watch, Ref, Vue,
} from 'vue-property-decorator'
import Creative from '@/models/Creative'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import WebMessage from '@/models/WebMessage'
// @ts-ignore
import { VastMediaFile } from '@dailymotion/vast-client'
import EventTracker from '@/components/EventTracker/EventTracker.vue'
import { macros, replaceMacros } from '@/models/Util'
import { cloneDeep } from 'lodash'
import { randomUUID } from '@/models/interface/Common'

@Component({
  components: {
    CompanyPicker,
    IconAction,
    SelectPicker,
    FormInput,
    EventTracker,
  },
})
export default class CreativeForm extends ViewModel {
  @Ref() readonly eventTracker!: EventTracker

  @Prop()
  public id!: string

  @Prop({ default: '' })
  public advertiser_id!: string

  @Prop({
    default: 'full',
  })
  public view_mode!: string

  @Prop({
    default: '',
  })
  public new_id!: string

  @Prop({
    default: true,
  })
  public show_sidebar!: boolean

  @Prop()
  public afterCloseRoute!: any

  public get macros() {
    return macros
  }

  public media_file: VastMediaFile | null = null

  public modal: any = {
    empty: false,
    preview: false,
  }

  public creative: Creative = new Creative()

  public loading: boolean = false

  public asset: any = null

  public busy = true

  public progress = 0

  public creative_types = [
    {
      name: 'Redirect (VAST tag)',
      value: 'vast',
    },
    {
      name: 'Video',
      value: 'video',
    },
  ]

  public unwrapper_types: any = [
    {
      name: 'Auto',
      value: 'auto',
    },
    {
      name: 'Always',
      value: 'always',
    },
    {
      name: 'No',
      value: 'no',
    },
  ]

  public trackers: any = {}

  public base_trackers: any = {
    ready: true,
    error: false,
    ad_system: '',
    validators: {
      pixalate: false,
      double_verify: false,
    },
    checks: {
      single_asset: false,
      high_bitrate: false,
      low_bitrate: false,
      depth: 0,
      categories: [],
    },
    show_domains: false,
    show_creatives: false,
    show_categories: false,
    domains: [],
    impression: {
      show: false,
      variant: 'danger',
      domains: [],
      urls: [],
    },
    creatives: [],
    pixels: {
      start: {
        name: 'Start',
        variant: 'danger',
        show: false,
        domains: [],
        urls: [],
      },
      firstQuartile: {
        name: 'First Quartile',
        variant: 'danger',
        show: false,
        domains: [],
        urls: [],
      },
      midpoint: {
        name: 'Midpoint',
        variant: 'danger',
        show: false,
        domains: [],
        urls: [],
      },
      thirdQuartile: {
        name: 'Third Quartile',
        variant: 'danger',
        show: false,
        domains: [],
        urls: [],
      },
      complete: {
        name: 'Complete',
        variant: 'danger',
        show: false,
        domains: [],
        urls: [],
      },
      click: {
        name: 'Click',
        variant: 'danger',
        show: false,
        domains: [],
        urls: [],
      },
      survey: {
        name: 'Survey',
        variant: 'danger',
        show: false,
        domains: [],
        urls: [],
      },
    },
  }

  public get creative_type_options() {
    return Creative.module.type_options
  }

  public get message() {
    if (this.creative.type === 'vast') return 'Saving Creative...'
    if (this.progress < 100) {
      return `Uploading Creative ${this.progress}%...`
    }
    return 'Validating Creative...'
  }

  public onModalHide(e: any) {
    if (e?.trigger == 'inspect') this.creative.inspect()
  }

  public save() {
    this.loading = true
    this.progress = 0

    this.creative
      .save(this.asset, (progressEvent: any) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length')
            || progressEvent.target.getResponseHeader('x-decompressed-content-length')
        if (totalLength !== null) {
          this.progress = Math.round((progressEvent.loaded * 100) / totalLength)
        }
      })
      .then((response: any) => {
        this.loading = false

        if (response.status == 200) {
          this.creative = new Creative()
          this.$emit('saved', response.data.result)
          if (this.view_mode == 'sidebar') {
            this.$emit('update:new_id', response.data.result.creative.id)
            this.init()
            this.$emit('update:show_sidebar', false)
          } else {
            // this.$router.push({ name: 'Creatives' })
            this.cancel()
          }
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public onSubmit() {
    if (this.creative?.type === 'vast') {
      if (this.creative?.vast_url?.includes('$')) {
        WebMessage.error(
          'Pelase ensure all macros use the format %%MACRO%%. $MACRO$ and similar variants are not supported. Unsupported macros should be removed.',
        )
        return
      }
    }
    this.loading = true

    this.creative
      .test()
      .then((r: any) => {
        if ((r.ads && r.ads.length > 0) || this.creative.type === 'video') {
          this.save()
        } else {
          this.loading = false
          this.modal.empty = true
        }
      })
      .catch(() => {
        WebMessage.error(
          'The provided tag is invalid, please make sure the tag is a valid HTTPS VAST Tag.',
          [
            {
              text: 'Inspect',
              action: () => {
                this.creative.inspect()
              },
            },
          ],
        )
        this.loading = false
      })
  }

  public cancel() {
    if (this.view_mode == 'sidebar') {
      this.$emit('update:show_sidebar', false)
    } else if (this.afterCloseRoute) {
      if (!this.afterCloseRoute.cancel) {
        this.$router.push(this.afterCloseRoute)
      } else {
        this.$emit('canceled')
      }
    } else {
      this.$router.push({ name: 'Creatives' })
    }
  }

  @Watch('show_sidebar')
  public onSidebarStatus() {
    this.init()
  }

  public toggleAllTrackers() {
    let target = !this.trackers.impression.show
    for (let k in this.trackers.pixels) {
      this.trackers.pixels[k].show = target
    }
    this.trackers.impression.show = target
  }

  @Watch('creative.vast_url')
  public onVastChange(val: string, old: string) {
    if (this.creative.type !== 'vast' || !this.creative.vast_url) {
      return
    }

    if (val != old && this.creative.vast_url) {
      this.creative.vast_url = replaceMacros(this.creative.vast_url)
    }

    let hightlight_domains = ['adrta.com', 'doubleverify.com']

    this.creative
      .test(true)
      .then((r: any) => {
        let trackers: any = cloneDeep(this.base_trackers)

        if (!r.ads || r.ads.length == 0) {
          trackers.error = true
          trackers.ready = false
          this.trackers = { ...trackers }
          return
        }
        r.ads.forEach((ad: any) => {
          if (ad.system.value) trackers.ad_system = ad.system.value

          trackers.checks.categories = ad.categories

          ad.impressionURLTemplates.forEach((t: any) => {
            let domain = t.url
            // get only the host name
            domain = domain.split('/')[2]
            trackers.domains.push(domain)
            trackers.impression.domains.push(domain)
            trackers.impression.urls.push(t.url)
          })

          if (ad.survey) {
            let domain = ad.survey
            // get only the host name
            domain = domain.split('/')[2]
            trackers.domains.push(domain)
            trackers.pixels.survey.domains.push(domain)
            trackers.pixels.survey.urls.push(ad.survey)
          }

          ad.creatives.forEach((c: any) => {
            for (let k in c.mediaFiles) {
              trackers.creatives.push(c.mediaFiles[k])
            }
            for (let k in trackers.pixels) {
              if (k !== 'click' && c.trackingEvents && c.trackingEvents[k]) {
                c.trackingEvents[k].forEach((url: any) => {
                  let domain = url
                  // get only the host name
                  domain = domain.split('/')[2]
                  trackers.domains.push(domain)
                  trackers.pixels[k].domains.push(domain)
                  trackers.pixels[k].urls.push(url)
                })
              }
            }

            if (c.videoClickThroughURLTemplate) {
              let domain = c.videoClickThroughURLTemplate.url
              // get only the host name
              domain = domain.split('/')[2]
              trackers.domains.push(domain)
              trackers.pixels.click.domains.push(domain)
              trackers.pixels.click.urls.push(c.videoClickThroughURLTemplate.url)
            }

            if (c.videoClickTrackingURLTemplates) {
              c.videoClickTrackingURLTemplates.forEach((t: any) => {
                let domain = t.url
                // get only the host name
                domain = domain.split('/')[2]
                trackers.domains.push(domain)
                trackers.pixels.click.domains.push(domain)
                trackers.pixels.click.urls.push(t.url)
              })
            }
          })
        })

        // Remove duplicates
        trackers.domains = [...new Set(trackers.domains)]
        trackers.impression.domains = [...new Set(trackers.impression.domains)]
        trackers.impression.urls = [...new Set(trackers.impression.urls)]

        if (trackers.impression.urls.length > 0) {
          trackers.impression.variant = 'success'

          for (let i = 0; i < hightlight_domains.length; i++) {
            for (let j = 0; j < trackers.impression.domains.length; j++) {
              if (trackers.impression.domains[j].includes(hightlight_domains[i])) {
                trackers.impression.variant = 'warning'
                break
              }
            }
          }
        }

        for (let k in trackers.pixels) {
          trackers.pixels[k].domains = [...new Set(trackers.pixels[k].domains)]
          trackers.pixels[k].urls = [...new Set(trackers.pixels[k].urls)]
          if (trackers.pixels[k].urls.length > 0) {
            trackers.pixels[k].variant = 'success'

            for (let i = 0; i < hightlight_domains.length; i++) {
              for (let j = 0; j < trackers.pixels[k].domains.length; j++) {
                if (trackers.pixels[k].domains[j].includes(hightlight_domains[i])) {
                  trackers.pixels[k].variant = 'warning'
                  break
                }
              }
            }
          }
        }

        trackers.domains.forEach((d: string) => {
          if (d.includes('adrta.com') || d == 'adrta.com') {
            trackers.validators.pixalate = true
          }
          if (d.includes('doubleverify.com')) {
            trackers.validators.double_verify = true
          }
        })

        // Creative checks
        trackers.checks.single_asset = trackers.creatives.length == 1
        trackers.checks.high_bitrate = trackers.creatives.every((c: any) => c.bitrate > 18000)
        trackers.checks.low_bitrate = trackers.creatives.every((c: any) => c.bitrate < 4000)

        this.trackers = { ...trackers }

        this.creative.wrapperDepth(true).then((depth: number) => {
          this.trackers.checks.depth = depth
        })
      })
      .catch(e => {
        let trackers = cloneDeep(this.base_trackers)
        trackers.ready = false
        trackers.error = true
        this.trackers = { ...trackers }
      })
  }

  public testTag() {
    if (this.creative.vast_url) {
      window.open(
        `https://developers.google.com/interactive-media-ads/docs/sdks/html5/vastinspector?tag=${encodeURIComponent(
          this.creative.vast_url.replace('http:', 'https:'),
        )}`,
        '_blank',
      )
    }
  }

  public previewCreative(creative: Creative) {
    this.creative = creative
    this.media_file = null
    this.creative.preview().then((r: VastMediaFile) => {
      if (r) {
        this.media_file = r
        this.modal.preview = true
      }
    })
  }

  public init() {
    if (this.id) {
      this.busy = true
      Creative.get(this.id).then(o => {
        if (o instanceof Creative) {
          this.creative = o
        }
        this.busy = false
      })
    } else {
      this.creative = new Creative()

      if (this.advertiser_id.length) {
        this.creative.advertiser_id = this.advertiser_id
      }

      this.busy = false
    }
  }

  public mounted() {
    this.init()
  }

  public editCreative(creative: any) {
    this.busy = true

    this.creative = Creative.toObject(creative)

    setTimeout(() => {
      this.busy = false
    }, 300)
  }
}
