
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'

import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import DistributionGoal from '@/models/DistributionGoal'
import { percentageMask } from '@/models/interface/Masks'
import { getModule } from 'vuex-module-decorators'
import InventoryModule from '@/store/model/InventoryModule'
import Inventory from '@/models/Inventory'
import WebMessage from '@/models/WebMessage'

@Component({
  components: {
    FormInput,
    IconAction,
    SelectPicker,
  },
})
export default class DistributionGoalGroup extends ViewModel {
  public must_filter: boolean = false

  @Prop({ default: true })
  public local_label!: boolean

  @Prop()
  public value!: any

  @Prop()
  public selected_adunits!: any

  @Prop()
  public exclude_adunits!: any

  @Prop({ default: '' })
  public adserver!: string

  @Prop({ default: false })
  public disabled!: any

  @Prop({ default: false })
  public hideButton!: boolean

  @Prop({ default: false })
  customInventory!: boolean

  @Prop()
  selectedMediaPackage!: any

  public loading: boolean = true

  public inventories: any = []

  public inventoryModule: InventoryModule = getModule(InventoryModule)

  public can_load: boolean = false

  public local_value: any = []

  public mode_options = [
    {
      value: 'percentage',
      name: 'Percentage',
    },
  ]

  public adunit_options: any = []

  public value_limit_reached: boolean = false

  @Watch('selected_adunits')
  public onValueChange(val: any) {
    if (val && this.can_load) {
      setTimeout(() => {
        this.getAdunits()
      }, 500)
    }
  }

  @Watch('local_value')
  public onLocalValueChange(val: any) {
    if (val && this.can_load) {
      this.$emit('input', val)
    }
  }

  @Watch('exclude_adunits')
  public onExcludeAdunitsChange(val: any) {
    if (val && val.length) {
      this.adunit_options = this.adunit_options.filter(
        (adunit: any) => !this.exclude_adunits.includes(adunit.value),
      )
    }
  }

  @Watch('totalOfAllValues')
  public onTotalOfAllValuesChange(val: any) {
    if (val > 100) {
      this.value_limit_reached = true
      this.toggleSaveBtn(true)
    } else {
      this.value_limit_reached = false
      this.toggleSaveBtn(false)
    }
  }

  public get unused_adunits() {
    return this.adunit_options.filter(
      (option: any) =>
        !this.local_value.some((unit: any) => unit.target_adunits.includes(option.value)),
    )
  }

  public get totalOfAllValues() {
    return this.local_value.reduce((acc: any, goal: any) => acc + goal.value, 0)
  }

  public get masks() {
    return {
      percentage: percentageMask,
    }
  }

  public mounted() {
    Inventory.module
      .getTree(this.adserver)
      .then((res: any) => {
        this.inventories = this.inventoryModule.all_data
        this.local_value = this.value ?? []
        this.getAdunits()
        this.can_load = true
        this.loading = false
      })
      .catch((err: any) => {
        this.can_load = true

        this.loading = false
      })
  }

  public removeUsedOptions() {
    this.getAdunits()

    this.adunit_options = this.unused_adunits

    Vue.set(this, 'adunit_options', this.unused_adunits)
  }

  public addGoal() {
    this.local_value.push(new DistributionGoal())
    // this.adunit_options = this.unused_adunits
    this.removeUsedOptions()
  }

  public removeGoal(index: number) {
    this.local_value.splice(index, 1)
  }

  public getAdunits() {
    let opts: any = []

    let selections = this.inventories.filter(i => this.selected_adunits.includes(i.id))

    selections.forEach((selected: any) => {
      opts.push({
        value: selected.id,
        name: selected.name,
      })
      if (selected.hierarchy_ids && selected.hierarchy_ids.length > 0) {
        let found = this.inventories.filter((i: any) => selected.hierarchy_ids.includes(i.id))

        found.forEach((fd: any) => {
          opts.push({
            value: fd.id,
            name: fd.name,
          })
        })

        found.forEach((element: any) => {
          let by_parent = this.inventories.filter((i: any) => i.parent_id === element.id)
          by_parent.forEach((bp: any) => {
            opts.push({
              value: bp.id,
              name: bp.name,
            })
          })
        })
      } else {
        let by_parent = this.inventories.filter(
          (i: any) => i.parent_id === selected.id || i.hierarchy_ids.includes(selected.id),
        )

        by_parent.forEach((bp: any) => {
          opts.push({
            value: bp.id,
            name: bp.name,
          })
        })
      }
    })

    this.adunit_options = opts.filter(
      (el: any, indx: number) => opts.findIndex((obj: any) => obj.value === el.value) === indx,
    )

    this.adunit_options = this.adunit_options.map((adunit: any) => ({
      value: adunit.value,
      name: this.formatName(adunit),
    }))
  }

  /**
   * This will work for two levels of childs
   */
  public formatName(target: DistributionGoal) {
    let temp_name: string = ''

    let adunit: Inventory = this.inventories.find(
      (inventory: Inventory) => inventory.id === target.value,
    )

    temp_name = adunit.name

    if (adunit.parent_id) {
      let found = this.inventories.find((inventory: Inventory) => inventory.id === adunit.parent_id)

      if (found.parent_id) {
        let found2 = this.inventories.find(
          (inventory: Inventory) => inventory.id === found.parent_id,
        )
        temp_name = `${found2.name} > ${found.name} > ${adunit.name}`
      } else {
        temp_name = `${found.name} > ${adunit.name}`
      }
    }

    return temp_name
  }

  public toggleSaveBtn(disable: boolean = false) {
    let btn = document.getElementById('navigate-next')

    if (disable) {
      btn?.setAttribute('disabled', 'disabled')
    } else {
      btn?.removeAttribute('disabled')
    }
  }

  public confirmIfNeeded(exe: any) {
    if (!this.customInventory && this.selectedMediaPackage && this.selectedMediaPackage.length) {
      // alert user
      WebMessage.doubleConfirm(
        'Are you sure you want to modify the Media Package original Distribution Goal?',
        'Modify Original Goals',
        'Yes, Modify Distribution Goals',
      ).then((res: any) => {
        if (res) {
          exe()
          this.$emit('update:customInventory', true)
        }
      })
    } else {
      exe()
    }
  }
}
