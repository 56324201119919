export default [
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'weight_mode',
    label: 'Weight Mode',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thStyle: { width: '20rem' },
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    class: 'text-center align-middle ',
    show: false,
  },
  {
    key: 'sequence',
    label: 'Sequence',
    sortable: true,
    class: 'text-center align-middle fixed-width-sm',
    show: false,
    thStyle: { width: '10rem' },
  },
  {
    key: 'weight_value',
    label: 'Weight Value',
    sortable: true,
    class: 'text-center align-middle ',
    show: false,
    thStyle: { width: '15rem' },
  },
  {
    key: 'start_at',
    label: 'Start At',
    sortable: true,
    class: 'text-center align-middle ',
    show: true,
  },

  {
    key: 'end_at',
    label: 'End At',
    sortable: true,
    class: 'text-center align-middle ',
    show: true,
  },

  {
    key: 'duration',
    label: 'Duration',
    sortable: true,
    class: 'text-center align-middle ',
    show: true,
  },
  {
    key: 'ready',
    label: 'Status',
    sortable: true,
    class: 'text-center align-middle ',
    show: true,
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    class: 'text-center align-middle ',
    show: true,
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    class: 'text-center align-middle ',
    show: false,
  },
  {
    key: 'action',
    label: 'Action',
    class: 'text-center align-middle ',
    sortable: false,
    show: true,
  },
]
