
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import DataTable from '@/components/DataTable/index.vue'
import MediaPlanItem from '@/models/MediaPlanItem'
import Order from '@/models/Order'
import LineItem from '@/models/LineItem'
import moment from 'moment'

@Component({
  components: {
    DataTable,
  },
})
export default class SyncModal extends ViewModel {
  @Prop({ default: 'Order' })
  public model_type!: string

  @Prop({ default: false })
  public _show!: boolean

  @Prop()
  public value!: any

  @Watch('value')
  public onValueChange(value: boolean) {
    if (value) {
      this.loadMediaPlanItem()
      this.show = true
    } else this.show = false
  }

  @Watch('_show')
  public onShowChange(value: boolean) {
    this.show = value
  }

  public show: boolean = false

  public busy: boolean = false

  public fields: any = [
    {
      key: 'checkbox',
      label: '',
      sortable: false,
      class: 'align-middle',
      show: true,
      type: 'checkbox',
    },
    {
      key: 'current',
      label: 'Current Value',
      sortable: false,
      class: 'text-center align-middle text-capitalize',
      show: true,
      thClass: 'align-middle text-center',
    },
    {
      key: 'new_value',
      label: 'New Value',
      sortable: false,
      class: 'text-center align-middle text-capitalize',
      show: true,
      thClass: 'align-middle text-center',
    },
  ]

  public checked_group: any = []

  public checked_control: any = []

  public allSelected: boolean = false

  public loading: boolean = true

  public table_data: any = []

  public media_plan_item: any = {}

  public allDataSynced: boolean = false

  public get indeterminate(): boolean {
    return this.checked_group.length > 0 && this.checked_group.length < this.table_data.length
  }

  @Watch('allSelected')
  public allSelectedOnChange(val: any) {
    if (val) {
      this.checked_group = this.table_data.map((item: any) => item.id)
    } else {
      this.checked_group = []
    }
  }

  public resetModalSate() {
    this.allDataSynced = false
    this.$emit('input', null)
  }

  public isGroupIndeterminate(group: any) {
    return false
  }

  public clearData(data: any, type: string) {
    if (!data || data === 'N/A') return 'N/A'
    if (type === 'array') {
      return !data.length ? 'N/A' : data.toString()
    }
    if (type === 'string') {
      return !data.length ? 'N/A' : data
    }
  }

  /**
   * Load the order's data
   *
   * The id is always the path of the property
   *
   * The prop is the property name of the model
   *
   * The value is the value of the property in MediaPlanItem
   *
   * @param media_plan_item
   */
  public loadOrderData(media_plan_item: MediaPlanItem) {
    this.table_data = [
      {
        id: 'media_plan_item.media_plan.agency_id',
        current: {
          label: 'Agency',
          value: this.value.agency.name,
          prop: 'agency_id',
        },
        new_value: {
          label: 'Contracted Amount',
          value: media_plan_item.media_plan?.agency?.name,
        },
      },
      {
        id: 'media_plan_item.media_plan.advertiser_id',
        current: {
          label: 'Advertiser',
          value: this.value.advertiser.name,
          prop: 'advertiser_id',
        },
        new_value: {
          label: 'Advertiser',
          value: media_plan_item.media_plan?.advertiser?.name,
        },
      },
      {
        id: 'media_plan_item.media_plan.id',
        current: {
          label: 'Media Plan',
          value: this.value.media_plan.name,
          prop: 'media_plan_id',
        },
        new_value: {
          label: 'Media Plan',
          value: media_plan_item.media_plan?.name,
        },
      },
      {
        id: 'media_plan_item.impressions',
        current: {
          label: 'Contracted Amount',
          value: this.value.contracted_amount,
          prop: 'contracted_amount',
        },
        new_value: {
          label: 'Contracted Amount',
          value: media_plan_item.impressions,
        },
      },
      {
        id: 'media_plan_item.media_plan.name | media_plan_item.name',
        current: {
          label: 'Name',
          value: this.value.name,
          prop: 'name',
        },
        new_value: {
          label: 'Name',
          value: `${media_plan_item.media_plan?.name} | ${media_plan_item.name}`,
        },
      },
    ]
  }

  public sortByProperty(items: any, prop: string) {
    if (!items || !items.length) return []
    return items.sort((a, b) => {
      if (a[prop] < b[prop]) {
        return -1
      }
      if (a[prop] > b[prop]) {
        return 1
      }
      return 0
    })
  }

  public async removeSameValues(time: number = 1300) {
    // remove all items that have the same value

    return new Promise(resolve => {
      setTimeout(() => {
        this.table_data = this.table_data.filter(
          (item: any) =>
            item.current.value
            && item.current.value !== item.new_value.value
            && item.current.value.toString() !== item.new_value.value.toString(),
        )
        if (!this.table_data.length) this.allDataSynced = true

        resolve(true)
      }, time)
    })
  }

  public checkIfTimeExists(date: any, time: any) {
    if (!date.includes(':')) {
      return moment(`${date} ${time}`)
    }
    return moment(date)
  }

  /**
   * Load the lineItem's data
   *
   * The id is always the path of the property
   *
   * The prop is the property name of the model
   *
   * The value is the value of the property in MediaPlanItem
   *
   * @param media_plan_item
   */
  public loadLineItemData(media_plan_item: MediaPlanItem) {
    this.table_data = [
      {
        id: 'media_plan_item.start_at',
        current: {
          label: 'Start Date',
          value: this.checkIfTimeExists(this.value.start_at, '00:00:00').format(
            'DD/MM/YYYY HH:mm:ss',
          ),
          prop: 'start_at',
        },
        new_value: {
          label: 'Start Date',
          value: this.checkIfTimeExists(media_plan_item.start_at, '00:00:00').format(
            'DD/MM/YYYY HH:mm:ss',
          ),
        },
      },
      {
        id: 'media_plan_item.end_at',
        current: {
          label: 'End Date',
          value: this.checkIfTimeExists(this.value.end_at, '23:59:59').format(
            'DD/MM/YYYY HH:mm:ss',
          ),
          prop: 'end_at',
        },
        new_value: {
          label: 'End Date',
          value: this.checkIfTimeExists(media_plan_item.end_at, '23:59:59').format(
            'DD/MM/YYYY HH:mm:ss',
          ),
        },
      },
      {
        id: 'media_plan_item.media_plan.agency_id',
        current: {
          label: 'Agency',
          value: this.value.agency.name,
          prop: 'agency_id',
        },
        new_value: {
          label: 'Agency',
          value: media_plan_item.media_plan?.agency?.name,
        },
      },
      {
        id: 'media_plan_item.media_plan.advertiser_id',
        current: {
          label: 'Advertiser',
          value: this.value.advertiser.name,
          prop: 'advertiser_id',
        },
        new_value: {
          label: 'Advertiser',
          value: media_plan_item.media_plan?.advertiser?.name,
        },
      },
      {
        id: 'media_plan_item.media_plan.id',
        current: {
          label: 'Media Plan',
          value: this.value.media_plan.name,
          prop: 'media_plan_id',
        },
        new_value: {
          label: 'Media Plan',
          value: media_plan_item.media_plan?.name,
        },
      },
      {
        id: 'media_plan_item.id',
        current: {
          label: 'Media Plan Item',
          value: this.value.media_plan_item.name,
          prop: 'media_plan_item_id',
        },
        new_value: {
          label: 'Media Plan Item',
          value: media_plan_item.name,
        },
      },
      {
        id: 'media_plan_item.impressions',
        current: {
          label: 'Contracted Amount',
          value: this.value.contracted_amount,
          prop: 'contracted_amount',
        },
        new_value: {
          label: 'Contracted Amount',
          value: media_plan_item.impressions,
        },
      },
      {
        id: 'media_plan_item.name',
        current: {
          label: 'Name',
          value: this.value.name,
          prop: 'name',
        },
        new_value: {
          label: 'Name',
          value: media_plan_item.name,
        },
      },
      {
        id: 'media_plan_item.metadata.targetting.include.dmas',
        current: {
          label: 'Targetting DMA (include)',
          value: this.clearData(this.value.targetting.include_dmas || [], 'array'),
          prop: 'targetting.include_dmas',
        },
        new_value: {
          label: 'Targetting DMA (include)',
          value: this.clearData(media_plan_item.metadata.targetting.include.dmas || [], 'array'),
        },
      },
      {
        id: 'media_plan_item.metadata.targetting.exclude.dmas',
        current: {
          label: 'Targetting DMA (exclude)',
          value: this.clearData(this.value.targetting.exclude_dmas || [], 'array'),
          prop: 'targetting.exclude_dmas',
        },
        new_value: {
          label: 'Targetting DMA (exclude)',
          value: this.clearData(media_plan_item.metadata.targetting.exclude.dmas || [], 'array'),
        },
      },
      {
        id: 'media_plan_item.metadata.targetting.include.countries',
        current: {
          label: 'Targetting Country (include)',
          value: this.clearData(this.value.targetting.include_countries || [], 'array'),
          prop: 'targetting.include_countries',
        },
        new_value: {
          label: 'Targetting Country (include)',
          value: this.clearData(
            media_plan_item.metadata.targetting.include.countries || [],
            'array',
          ),
        },
      },
      {
        id: 'media_plan_item.metadata.targetting.exclude.countries',
        current: {
          label: 'Targetting Country (exclude)',
          value: this.clearData(this.value.targetting.exclude_countries || [], 'array'),
          prop: 'targetting.exclude_countries',
        },
        new_value: {
          label: 'Targetting Country (exclude)',
          value: this.clearData(
            media_plan_item.metadata.targetting.exclude.countries || [],
            'array',
          ),
        },
      },
      {
        id: 'media_plan_item.metadata.targetting.include.states',
        current: {
          label: 'Targetting State (include)',
          value: this.clearData(this.value.targetting.include_states || [], 'array'),
          prop: 'targetting.include_states',
        },
        new_value: {
          label: 'Targetting State (include)',
          value: this.clearData(media_plan_item.metadata.targetting.include.states || [], 'array'),
        },
      },
      {
        id: 'media_plan_item.metadata.targetting.exclude.states',
        current: {
          label: 'Targetting State (exclude)',
          value: this.clearData(this.value.targetting.exclude_states || [], 'array'),
          prop: 'targetting.exclude_states',
        },
        new_value: {
          label: 'Targetting State (exclude)',
          value: this.clearData(media_plan_item.metadata.targetting.exclude.states || [], 'array'),
        },
      },
      {
        id: 'media_plan_item.metadata.targetting.include.cities',
        current: {
          label: 'Targetting City (include)',
          value: this.clearData(this.value.targetting.include_cities || [], 'array'),
          prop: 'targetting.include_cities',
        },
        new_value: {
          label: 'Targetting City (include)',
          value: this.clearData(media_plan_item.metadata.targetting.include.cities || [], 'array'),
        },
      },
      {
        id: 'media_plan_item.metadata.targetting.exclude.cities',
        current: {
          label: 'Targetting City (exclude)',
          value: this.clearData(this.value.targetting.exclude_cities || [], 'array'),
          prop: 'targetting.exclude_cities',
        },
        new_value: {
          label: 'Targetting City (exclude)',
          value: this.clearData(media_plan_item.metadata.targetting.exclude.cities || [], 'array'),
        },
      },
      {
        id: 'media_plan_item.metadata.targetting.include.zipcodes',
        current: {
          label: 'Targetting Zipcodes (include)',
          value: this.clearData(this.value.targetting.include_zips || [], 'array'),
          prop: 'targetting.include_zips',
        },
        new_value: {
          label: 'Targetting Zipcodes (include)',
          value: this.clearData(
            media_plan_item.metadata.targetting.include.zipcodes || [],
            'array',
          ),
        },
      },
      {
        id: 'media_plan_item.metadata.targetting.exclude.zipcodes',
        current: {
          label: 'Targetting Zipcodes (exclude)',
          value: this.clearData(this.value.targetting.exclude_zips || [], 'array'),
          prop: 'targetting.exclude_zips',
        },
        new_value: {
          label: 'Targetting Zipcodes (exclude)',
          value: this.clearData(
            media_plan_item.metadata.targetting.exclude.zipcodes || [],
            'array',
          ),
        },
      },
    ]
    if (media_plan_item.media_package) {
      let sorted_data = {
        include_media_packages: media_plan_item?.media_package?.adunits?.map(a => a.name).sort(),
        include_line_item_adunits: this.value.adunits?.included?.map(a => a.name).sort(),
        exclude_media_packages: media_plan_item?.media_package?.exclude_adunits
          ?.map(a => a.name)
          .sort(),
        exclude_line_item_adunits: this.value.adunits?.excluded?.map(a => a.name).sort(),
      }

      let adunits = [
        {
          id: 'media_plan_item.media_package.adunits',
          current: {
            label: 'Targetting adunits (include)',
            value: sorted_data.include_line_item_adunits,
            prop: 'targetting.include_adunits',
            type: 'list',
          },
          new_value: {
            label: 'Targetting adunits (include)',
            value: sorted_data.include_media_packages,
            type: 'list',
          },
        },
        {
          id: 'media_plan_item.media_package.exclude_adunits',
          current: {
            label: 'Targetting adunits (exclude)',
            value: sorted_data.exclude_line_item_adunits,
            prop: 'targetting.exclude_adunits',
            type: 'list',
          },
          new_value: {
            label: 'Targetting adunits (exclude)',
            value: sorted_data.exclude_media_packages,
            type: 'list',
          },
        },
      ]
      this.table_data = this.table_data.concat(adunits)
    }
    // distribution_goals
    //= ============================= mode 1==============================
    let distribution_goals_new_val: any = {}
    let distribution_goals_current_values: any = {}

    if (
      media_plan_item?.media_package?.distribution_goals
      && media_plan_item?.media_package?.distribution_goals.length
    ) {
      // @ts-ignore
      if (media_plan_item?.media_package?.distribuition_goal_list) {
        // @ts-ignore
        const distribuition_goal_list = media_plan_item?.media_package?.distribuition_goal_list || []

        distribuition_goal_list.forEach((list_item: any, index: number) => {
          distribution_goals_new_val[index] = {
            adunits: list_item.target_adunits.map((a: any) => a.name),
            value: list_item.value,
            mode: list_item.mode,
          }
        })
      }
    }
    if (this.value.distribuition_goal_list && this.value.distribuition_goal_list.length) {
      this.value.distribuition_goal_list.forEach((list_item: any, index: number) => {
        distribution_goals_current_values[index] = {
          adunits: list_item.target_adunits.map((a: any) => a.name),
          value: list_item.value,
          mode: list_item.mode,
        }
      })
    }

    const createLabel = (index: number, value: any, mode: any) => {
      let label = `#${index} Distribution Goals`
      if (value !== undefined) {
        if (mode === 'percentage') {
          label += `- (${value}%)`
        } else {
          label += `- (${value})`
        }
      }

      return label
    }

    const add = (current_val: any, new_val: any, index: any) => {
      this.table_data.push({
        id: `media_plan_item.media_package.distribution_goals:${index - 1}`,
        index: index - 1,
        current: {
          label: createLabel(index, current_val.value, current_val.mode),
          value: current_val !== 'N/A' ? current_val.adunits : current_val,
          prop: 'distribution_goals',
          type: current_val !== 'N/A' ? 'list' : null,
        },
        new_value: {
          label: createLabel(index, new_val.value, new_val.mode),
          value: new_val !== 'N/A' ? new_val.adunits : new_val,
          type: new_val !== 'N/A' ? 'list' : null,
        },
      })
    }

    // check who has the biggest length

    if (
      Object.keys(distribution_goals_new_val).length
      > Object.keys(distribution_goals_current_values).length
    ) {
      // start with distribution_goals_new_val

      for (const key in distribution_goals_new_val) {
        let current = distribution_goals_current_values[key] ?? 'N/A'
        let new_val = distribution_goals_new_val[key] ?? 'N/A'

        add(current, new_val, Number(key) + 1)
      }
    } else {
      for (const key in distribution_goals_current_values) {
        let current = distribution_goals_current_values[key] ?? 'N/A'
        let new_val = distribution_goals_new_val[key] ?? 'N/A'

        add(current, new_val, Number(key) + 1)
      }
    }

    if (media_plan_item.media_plan?.isLinear) {
      let optout_days = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ]
      optout_days = optout_days.filter(day => !media_plan_item.metadata.days.includes(day))

      let time_restriction_include_index = this.value.targetting.time_restrictions.findIndex(
        (item: any) =>
          item.type === 'include'
          && item.days.every((val: any) => media_plan_item.metadata.days.includes(val)),
      )

      let time_restriction_include = this.value.targetting.time_restrictions[time_restriction_include_index]

      let time_restriction = [
        {
          id: 'media_plan_item.metadata.flight_time.start_at',
          current: {
            label: 'Targetting time restriction Start Time (include)',
            value: time_restriction_include?.time_start_at || 'Not set',
            prop: 'targetting.time_restrictions',
            search_index: time_restriction_include_index,
            object_prop: 'time_start_at',
          },
          new_value: {
            label: 'Flight Time Start Time',
            value: media_plan_item.metadata.flight_time.start_at,
          },
        },
        {
          id: 'media_plan_item.metadata.flight_time.end_at',
          current: {
            label: 'Targetting time restriction End Time (include)',
            value: time_restriction_include?.time_end_at || 'Not set',
            prop: 'targetting.time_restrictions',
            search_index: time_restriction_include_index,
            object_prop: 'time_end_at',
          },
          new_value: {
            label: 'Flight Time Start Time',
            value: media_plan_item.metadata.flight_time.end_at,
          },
        },
        {
          id: 'media_plan_item.metadata.days',
          current: {
            label: 'Targetting time restriction Days (include)',
            value: this.clearData(time_restriction_include?.days || [], 'array'),
            prop: 'targetting.time_restrictions',
            search_index: time_restriction_include_index,
            object_prop: 'days',
          },
          new_value: {
            label: 'Days',
            value: this.clearData(media_plan_item.metadata.days || [], 'array'),
          },
        },
      ]

      this.table_data.concat(time_restriction)

      if (optout_days.length > 0) {
        let time_restriction_exclude_index = this.value.targetting.time_restrictions.findIndex(
          (item: any) =>
            item.type === 'exclude' && item.days.every((val: any) => optout_days.includes(val)),
        )

        let time_restriction_exclude = this.value.targetting.time_restrictions[time_restriction_exclude_index]

        let time_restriction_exclude_data = [
          {
            id: 'default_start_at',
            current: {
              label: 'Targetting time restriction Start Time (exclude)',
              value: time_restriction_exclude?.time_start_at || 'Not set',
              prop: 'targetting.time_restrictions',
              search_index: time_restriction_exclude_index,
              object_prop: 'time_start_at',
            },
            new_value: {
              label: 'Default Start Time',
              value: '00:00',
            },
          },
          {
            id: 'default_end_time',
            current: {
              label: 'Targetting time restriction End Time (exclude)',
              value: time_restriction_exclude?.time_end_at || 'Not set',
              prop: 'targetting.time_restrictions',
              search_index: time_restriction_exclude_index,
              object_prop: 'time_end_at',
            },
            new_value: {
              label: 'Default End Time',
              value: '23:59',
            },
          },
          {
            id: 'default_days',
            current: {
              label: 'Targetting time restriction days (exclude)',
              value: time_restriction_exclude?.days || 'Not set',
              prop: 'targetting.time_restrictions',
              search_index: time_restriction_exclude_index,
              object_prop: 'days',
            },
            new_value: {
              label: 'Targetting time restriction days',
              value: optout_days,
            },
          },
        ]

        this.table_data.concat(time_restriction_exclude_data)
      }
    }
  }

  public loadMediaPlanItem() {
    this.loading = true

    MediaPlanItem.find(this.value.media_plan_item_id, { appends: true }).then(
      (media_plan_item: MediaPlanItem) => {
        this.media_plan_item = media_plan_item
        if (this.model_type === 'Order') {
          this.loadOrderData(media_plan_item)
        }

        if (this.model_type === 'LineItem') {
          this.loadLineItemData(media_plan_item)
        }

        this.removeSameValues().then(() => {
          this.loading = false
          setTimeout(() => {
            this.allSelected = true
          }, 500)
        })
      },
    )
  }

  public syncSelectedData() {
    if (this.model_type === 'Order') {
      this.syncOrderData()
    }
    if (this.model_type === 'LineItem') {
      this.syncLineItemData()
    }
  }

  public syncLineItemData() {
    this.busy = true
    let temp_line_item: LineItem | any = this.value
    this.checked_group.forEach((item_prop: any) => {
      let variable = item_prop.replaceAll('media_plan_item.', '')
      let obj = this.table_data.find((item: any) => item.id === item_prop)
      let synced_value: any = null

      if (variable.includes(' | ')) {
        let prop_arr = variable.split(' | ')
        let val = ''
        prop_arr.forEach((prop: any, index: number) => {
          if (prop.includes('.')) {
            val += prop
              .split('.')
              .reduce((acc: any, curr: any) => (acc ? acc[curr] : undefined), this.media_plan_item)
          } else {
            if (index > 0 && index <= prop_arr.length - 1) val += ' | '
            val += this.media_plan_item[prop]
          }
        })
        synced_value = val
      } else {
        synced_value = variable
          .split('.')
          .reduce((acc: any, curr: any) => (acc ? acc[curr] : undefined), this.media_plan_item)
      }

      // check synced_value to format the outcome
      if (item_prop.includes('media_package.adunits')) {
        synced_value = synced_value.map((item: any) => item.id)
      }
      if (obj.current.prop === 'start_at') {
        synced_value = this.checkIfTimeExists(synced_value, '00:00:00').format(
          'YYYY-MM-DD HH:mm:ss',
        )
      } else if (obj.current.prop === 'end_at') {
        synced_value = this.checkIfTimeExists(synced_value, '23:59:59').format(
          'YYYY-MM-DD HH:mm:ss',
        )
      }

      if (obj.current.prop.includes('targetting.')) {
        let p = obj.current.prop.replace('targetting.', '')
        if (obj.current.hasOwnProperty('search_index') && obj.current.search_index > -1) {
          if (!temp_line_item.targetting[p].length) {
            temp_line_item.targetting[p] = []
          }
          if (obj.current.object_prop) {
            temp_line_item.targetting[p][obj.current.search_index][obj.current.object_prop] = synced_value || []
          } else {
            temp_line_item.targetting[p][obj.current.search_index] = synced_value || []
          }
        } else {
          temp_line_item.targetting[obj.current.prop.replace('targetting.', '')] = synced_value || []
        }
      } else if (variable.includes(':')) {
        // it has an array
        if (obj.current.prop === 'distribution_goals') {
          let new_value = this.media_plan_item.media_package.distribution_goals[obj.index]

          if (!temp_line_item[obj.current.prop] || !temp_line_item[obj.current.prop].length) {
            temp_line_item[obj.current.prop] = []
            temp_line_item[obj.current.prop].push(new_value)
          } else {
            temp_line_item[obj.current.prop][obj.index] = new_value
          }
        }
        // if needed add more props here
      } else {
        temp_line_item[obj.current.prop] = synced_value || []
      }
    })
    temp_line_item
      .save()
      .then((res: any) => {
        this.busy = false
        this.resetModalSate()
        this.$emit('synced', true)
      })
      .catch(() => {
        this.busy = false
      })
  }

  public syncOrderData() {
    this.busy = true
    let temp_order: Order | any = this.value
    this.checked_group.forEach((item_prop: any) => {
      let variable = item_prop.replaceAll('media_plan_item.', '')
      let obj = this.table_data.find((item: any) => item.id === item_prop)
      let synced_value: any = null

      if (variable.includes(' | ')) {
        let prop_arr = variable.split(' | ')
        let val = ''
        prop_arr.forEach((prop: any, index: number) => {
          if (prop.includes('.')) {
            val += prop
              .split('.')
              .reduce((acc: any, curr: any) => (acc ? acc[curr] : undefined), this.media_plan_item)
          } else {
            if (index > 0 && index <= prop_arr.length - 1) val += ' | '
            val += this.media_plan_item[prop]
          }
        })
        synced_value = val
      } else {
        synced_value = variable
          .split('.')
          .reduce((acc: any, curr: any) => (acc ? acc[curr] : undefined), this.media_plan_item)
      }
      if (obj.current.prop === 'start_at') {
        synced_value = this.checkIfTimeExists(synced_value, '00:00:00').format(
          'YYYY-MM-DD HH:mm:ss',
        )
      } else if (obj.current.prop === 'end_at') {
        synced_value = this.checkIfTimeExists(synced_value, '23:59:59').format(
          'YYYY-MM-DD HH:mm:ss',
        )
      }
      temp_order[obj.current.prop] = synced_value
    })

    temp_order.save().then((response: any) => {
      this.resetModalSate()
      this.media_plan_item = {}
      this.busy = false
    })
  }
}
