
import { Component, Prop, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'

@Component
export default class RowToggle extends ViewModel {
  @Prop()
  public value!: any

  @Prop({ required: true })
  public toggleValue!: string

  @Prop({ default: false })
  public disabled!: boolean

  @Prop()
  public label!: string

  @Prop()
  public contentCols!: any

  @Prop()
  public labelCols!: any

  public get local_value() {
    return this.value
  }

  public set local_value(value: any) {
    this.$emit('input', value)
  }
}
