
import ViewModel from '@/models/ViewModel'
import { Component, Ref, Vue } from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import LineItem from '@/models/LineItem'
import AuditHistory from '@/components/AuditHistory/AuditHistoryTable.vue'
import SyncModal from '@/components/MediaPlanItemSyncModal/SyncModal.vue'
import LineItemsTable from './components/LineItemsTable.vue'

@Component({
  components: {
    LineItemsTable,
    IconAction,
    AuditHistory,
    SyncModal,
  },
})
export default class LineItemHome extends ViewModel {
  @Ref() readonly audit_history!: any

  @Ref() readonly line_item_table!: any

  public modal: any = {
    history: false,
  }

  public syncLineItem: LineItem | any = {}

  public viewHistory(line_item: LineItem) {
    this.modal.history = true

    this.audit_history.setModel(line_item)

    Vue.set(this.audit_history, 'ready', true)

    Vue.set(this.audit_history, 'history_loading', false)

    this.audit_history.refresh(300)
  }

  public syncLineItemData(line_item: LineItem) {
    this.syncLineItem = line_item
  }

  public refresh() {
    this.line_item_table.refresh()
  }
}
