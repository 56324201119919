
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import RowToggle from '@/components/RowToggle/RowToggle.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import LineItem from '@/models/LineItem'
import FormInput from '@/components/FormInput/FormInput.vue'
import { percentageMask, currencyMask } from '@/models/interface/Masks'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import InsertionOrderItemPicker from '@/components/InsertionOrderItemPicker/InsertionOrderItemPicker.vue'
import WebMessage from '@/models/WebMessage'
import moment from 'moment'

@Component({
  components: {
    RowToggle,
    DatePicker,
    SelectPicker,
    FormInput,
    InsertionOrderItemPicker,
  },
})
export default class BatchLineItem extends ViewModel {
  @Ref() readonly validator!: any

  public modals = {
    batchUpdate: false,
  }

  @Prop()
  public selectedItems!: LineItem[]

  public selected_to_update: string[] = []

  public temp_batch_update: LineItem = new LineItem()

  public status_list = [
    {
      name: 'Ready',
      value: 'ready',
    },
    {
      name: 'Paused',
      value: 'paused',
    },
  ]

  public get masks() {
    return { percentageMask, currencyMask }
  }

  public get mediaPlanIds() {
    const ids = this.selectedItems.map(item => item.media_plan_id)

    return Array.from(new Set(ids))
  }

  public mounted() {
    if (!this.temp_batch_update.metrics) {
      this.temp_batch_update.metrics = {}
    }
    this.temp_batch_update.contracted_amount = 0
  }

  public useRuleWhenSelected(key: string, rules: any) {
    if (this.isEnabled(key)) {
      return rules
    }
    return ''
  }

  public isEnabled(name: string) {
    return this.selected_to_update.includes(name)
  }

  public open() {
    this.temp_batch_update.status = 'paused'

    const max_duration = this.selectedItems.reduce((acc, item) => {
      if (!acc) {
        return moment(item.end_at).diff(moment(item.start_at), 'days')
      }
      const duration = moment(item.end_at).diff(moment(item.start_at), 'days')
      if (duration > acc) {
        return duration
      }
      return acc
    }, 0) + 1

    this.temp_batch_update.start_at = this.selectedItems
      .reduce((acc: any, item) => {
        if (!acc) {
          return moment(item.end_at)
        }
        if (acc.isBefore(item.end_at)) {
          return moment(item.end_at)
        }
        return acc
      }, null)
      .add(1, 'day')
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss')

    this.temp_batch_update.end_at = moment(this.temp_batch_update.start_at)
      .add(max_duration, 'days')
      .format('YYYY-MM-DD HH:mm:ss')
    this.modals.batchUpdate = true
  }

  public batchCreateCopy() {
    this.loading = true
    // public static batchCopyCreate(line_item_ids: any[], payload: any)
    let line_item_ids = this.selectedItems.map(item => item.id)

    // map the payload from  this.temp_batch_update
    let payload: any = {
      start_at: this.temp_batch_update.start_at,
      end_at: this.temp_batch_update.end_at,
      impressions: this.temp_batch_update.contracted_amount,
      status: this.temp_batch_update.status,
      media_plan_item_id: this.temp_batch_update.media_plan_item_id,
    }

    if (this.temp_batch_update.copy_quantity > 1) {
      payload.copy_quantity = this.temp_batch_update.copy_quantity
    }

    LineItem.batchCopyCreate(line_item_ids, payload).then(data => {
      this.loading = false
      if (!data || data.length === 0) {
        return
      }
      WebMessage.success('Line items created successfully!')
      this.modals.batchUpdate = false
      this.$emit('saved', data)
    })
  }
}
