
import ViewModel from '@/models/ViewModel'
import {
  isArray, isEqual, isNumber, isObject, isString, transform,
} from 'lodash'
import moment from 'moment'
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import AuditHistory from '@/models/AuditHistory'
import DataTable from '../DataTable/index.vue'
import IconAction from '../IconAction/IconAction.vue'
import FormInput from '../FormInput/FormInput.vue'
// @ts-ignore

@Component({
  components: {
    DataTable,
    IconAction,
    FormInput,
  },
})
export default class AuditHistoryTable extends ViewModel {
  @Ref() public historyTable!: DataTable

  @Prop()
  public result_name!: string

  @Prop()
  public table_query!: string

  public history_loading: boolean = false

  public ready: boolean = false

  public history_records: number = 0

  public item_history: any = []

  public temp_audit: AuditHistory | null = null

  public model_instance: any = null

  public current_index: number = 0

  public per_page: number = 25

  public page: number = 1

  public records: number = 0

  public query: any = []

  public modal: any = {
    delete: false,
    history: false,
  }

  public history: Array<AuditHistory> = []

  public history_details_fields_created = [
    {
      key: 'key',
      label: 'Field',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
    {
      key: 'new',
      label: 'New value',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
  ]

  public history_details_fields = [
    {
      key: 'key',
      label: 'Field',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
    {
      key: 'old',
      label: 'Old value',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
    {
      key: 'new',
      label: 'New value',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
  ]

  public history_fields: any = [
    {
      key: 'user',
      label: 'User',
      class: 'text-center align-middle text-capitalize',
      show: false,
    },
    {
      key: 'event',
      label: 'Action',
      class: 'text-center align-middle text-capitalize',
      show: false,
    },
    {
      key: 'created_at',
      label: 'Date',
      class: 'text-center align-middle text-capitalize',
      show: false,
    },
    {
      key: 'has_notes',
      label: 'Contains Notes',
      class: 'text-center align-middle text-capitalize',
      show: false,
      type: 'badge',
      color: (history: any) => (history.notes ? 'info' : 'default'),
    },
    {
      key: 'action',
      label: '',
      class: 'text-center align-middle text-capitalize',
      show: false,
    },
  ]

  public getType(val: any) {
    let str = val.replace('App\\Models\\', '')

    let opt: any = {
      LineItem: 'Line Item',
      InvoiceItem: 'Invoice Item',
      Invoice: 'Invoice',
      Order: 'Order',
      Inventory: 'Inventory',
    }

    return opt[str] ?? str
  }

  public toggleNotes(data: any) {
    Vue.set(this, 'temp_audit', Object.assign(new AuditHistory(), data.item))
    data.item.toggleNotes()
    this.$root.$emit('bv::toggle::collapse', `notes-${data.index}`)
  }

  public saveTempNotes(data: any) {
    data.item.save().then((r: any) => {
      data.item.toggleNotes()
      // this.$root.$emit('bv::toggle::collapse', `notes-${data.index}`)
      // update the history
      // this.viewHistory(this.model_instance, {
      //   unset_loading: true,
      // })
    })
  }

  public mounted() {
    // this.ready = true
    this.blockPageOverflow()
  }

  public beforeDestroy() {
    this.blockPageOverflow()
  }

  public setModel(instance) {
    Vue.set(this, 'model_instance', instance)

    // this.model_instance = instance
  }

  public refresh(tm: number = 0) {
    setTimeout(() => {
      this.historyTable.refresh()
    }, tm)
  }

  public blockPageOverflow() {
    // html style overflow-y: hidden;
    // get html element
    let html = document.querySelector('html')
    html?.classList.toggle('overflow-hidden')
  }

  public async rows(ctx) {
    this.history_loading = true

    return this.model_instance
      .getHistory({
        page_size: ctx.perPage,
        page: ctx.currentPage,
        order_by: ctx.sortBy,
        order: ctx.sortDesc ? 'desc' : 'asc',
        query: this.query,
      })
      .then((response: any) => {
        if (!response.data.result[this.result_name].length) {
          this.history_loading = false
          return false
        }

        return this.waitForData(response).then(r => {
          let data = r.filter((h: any) => h.event !== 'created')

          data = data.map((h: any, index: number) => {
            let g = Object.assign(new AuditHistory(), h)
            g.updateChecksum()

            return g
          })

          this.history_loading = false

          this.history_records = response.data.result.records

          return data.filter(r => r.details && r.details.length)
        })
      })
  }

  public viewHistory(model: any, settings: any = null) {
    const set_loading = (val: boolean) => (this.history_loading = val)
    // switch loading states
    if (settings) {
      if (settings.unset_loading) {
        set_loading(false)
      } else {
        set_loading(true)
      }
    } else {
      set_loading(true)
    }

    this.modal.history = true

    if (!this.model_instance) {
      this.model_instance = model
    }

    let ctx: any = {
      page_size: 25,
      page: 1,
      order_by: 'created_at',
      order: 'desc',
      query: [],
    }

    if (settings && settings.context) {
      if (settings.context.on_table) {
        ctx.page_size = settings.context.perPage
        ctx.page = settings.context.currentPage
        this.per_page = settings.context.perPage
        this.page = settings.context.currentPage
      } else {
        ctx = { ...ctx, ...settings.context }
      }
    }

    return model
      .getHistory({
        ...ctx,
      })
      .then((response: any) => {
        if (!response.data.result[this.result_name].length) {
          this.history = []
          this.history_loading = false
          return false
        }

        this.waitForData(response).then(r => {
          Vue.set(this, 'history', null)

          Vue.set(
            this,
            'history',
            r.filter((h: any) => h.event !== 'created'),
          )

          this.history = this.history.map((h: any, index: number) => {
            let g = Object.assign(new AuditHistory(), h)
            g.updateChecksum()

            return g
          })

          this.history_loading = false

          if (settings && settings.context) {
            if (settings.context.on_table) {
              Vue.set(this.historyTable, 'page_size', settings.context.perPage)
              Vue.set(this.historyTable, 'page', settings.context.currentPage)
              this.historyTable.refresh()
            }
          }
        })

        this.history_records = response.data.result.records

        return this.history
      })
  }

  /**
   *  Maps the changes from The response of the  history
   *
   *  The changed payload will be injected in details
   *
   * @param response
   */
  public async waitForData(response: any) {
    return Promise.all(
      response.data.result[this.result_name].map(async (i: any) => {
        if (i.event === 'created') {
          i.old_values = this.nullObj(JSON.parse(JSON.stringify(i.new_values || [])))
        }
        i.details = await this.checkDiff(
          JSON.parse(JSON.stringify(i.old_values || [])),
          JSON.parse(JSON.stringify(i.new_values || [])),
        )
        return i
      }),
    )
  }

  public async waitForItemData(response: any) {
    return Promise.all(
      response.data.result[this.result_name].map(async (i: any) => {
        if (i.event === 'created') {
          i.old_values = this.nullObj(JSON.parse(JSON.stringify(i.new_values)))
        }
        i.details = await this.checkDiff(i.old_values, i.new_values)
        return i
      }),
    )
  }

  public isUrl(string) {
    try {
      const url = new URL(string)
      return url.protocol === 'http:' || url.protocol === 'https:'
    } catch (err) {
      return false
    }
  }

  public isValueFalt(val: any) {
    if (isNumber(val) || isString(val) || !val.length) {
      return true
    }
    return false
  }

  // use for history arrays
  public updateValues(item: any) {
    if (!item.length) return 'N/A'
    let ob: any = {}

    let acc: any = []
    if (isArray(item)) {
      if (isString(item[0])) {
        ob.type = 'array'
        ob.key = ''
        ob.value = item
        acc.push(ob)
        ob = {}
      }
      if (isObject(item[0])) {
        let ks = Object.keys(item[0])

        ks.forEach(k => {})

        ob.type = 'array'
        ob.key = ''
        ob.value = item
        acc.push(ob)
        ob = {}
      }
    } else if (isObject(item)) {
      let keys: any = Object.keys(item)
      keys.forEach((k: any) => {
        if (isArray(item[k])) {
          ob.type = 'array'
        }
        if (isString(item[k])) {
          ob.type = 'string'
          if (this.isUrl(item[k])) {
            ob.type = 'url'
          }
        }
        ob.key = k
        ob.value = item[k]
        acc.push(ob)
        ob = {}
      })
    }
    return acc
  }

  public detectArrayType(arr: any) {
    let isNum = true
    let isObj = true
    let isStr = true
    let type_name = 'array'

    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] !== 'number') {
        isNum = false
      }
      if (typeof arr[i] !== 'object' || Array.isArray(arr[i])) {
        isObj = false
      }
      if (typeof arr[i] !== 'string') {
        isStr = false
      }
    }

    if (isNum) {
      return `${type_name}.number`
    }
    if (isObj) {
      return `${type_name}.object`
    }
    if (isStr) {
      return `${type_name}.string`
    }
    return `${type_name}.mixed`
  }

  // refactoring...
  // maybe for each model make a function that validates its data
  public async checkDiff(old_values: any, new_values: any) {
    let clear = (s: any) => {
      if (isString(s)) {
        return s?.replaceAll('_', ' ')?.replaceAll('id', '')?.replace('00:00:00', '') || s
      }
      return s
      //
    }
    // first level of keys
    let json_columns = [
      'metadata',
      'targetting',
      'tracking_events',
      'distribution_goals',
      'frequency_caps',
    ]
    //
    let diff_values = this.difference(old_values, new_values)
    let diff: any = []
    let add = (d: any) => diff.push(d)

    let arrayExtract = async (old: any, changed: any, key_name: any = null) => {
      // extracts the array values
      changed.map(async (item: any, i: number) => {
        let old_property = old[i] ? old[i] : 'N/A'
        if (key_name === 'tracking_events') {
          let obj: any = {
            key: `${clear(key_name)}-${i + 1}`,
            old: old_property,
            new: item || 'N/A',
          }
          let can_add = await this.removeRepeats(obj)
          if (can_add) add(obj)
        }
      })
    }

    let keys: any = Object.keys(diff_values)

    let extract = async (old_meta: any, changed_meta: any) => {
      let chng_keys = Object.keys(changed_meta ?? {})
      let meta_key: any = null
      for await (meta_key of chng_keys) {
        if (isObject(changed_meta[meta_key])) {
          if (meta_key === 'client_data') {
            let client_data_keys = Object.keys(changed_meta[meta_key])

            for await (const cdk of client_data_keys) {
              let name = meta_key.replace('_data', ` ${cdk}`)

              let obj: any = {
                key: clear(name),
                old: !old_meta.client_data.hasOwnProperty(cdk)
                  ? 'N/A'
                  : old_meta[meta_key][cdk]
                    ? old_meta[meta_key][cdk]
                    : 'N/A',
                new: changed_meta[meta_key][cdk] ? changed_meta[meta_key][cdk] : 'N/A',
              }

              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'custom_billing') {
            let custom_billing_keys = Object.keys(changed_meta.custom_billing)

            for await (const cbk of custom_billing_keys) {
              let name = `${clear(meta_key)} ${cbk.replace('billing_', ' ')}`
              let obj: any = {
                key: name,
                old: old_meta.custom_billing[cbk] ? old_meta.custom_billing[cbk] : 'N/A',
                new: changed_meta.custom_billing[cbk] ? changed_meta.custom_billing[cbk] : 'N/A',
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'header') {
            let header_keys = Object.keys(changed_meta.header)
            for await (const hk of header_keys) {
              let name = clear(hk)
              let o = await this.checkData(old_meta.header[hk] || 'N/A', hk)
              let n = await this.checkData(changed_meta.header[hk] || 'N/A', hk)
              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'payee') {
            let payee_keys = Object.keys(changed_meta.payee)

            for await (const pk of payee_keys) {
              let name = `Payee ${clear(pk)}`
              let o = await this.checkData(old_meta.payee[pk] || 'N/A', pk)
              let n = await this.checkData(changed_meta.payee[pk] || 'N/A', pk)

              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'station') {
            let station_keys = Object.keys(changed_meta.station)

            for await (const sk of station_keys) {
              let name = `station ${clear(sk)}`
              let o = await this.checkData(old_meta.station[sk] || 'N/A', sk)
              let n = await this.checkData(changed_meta.station[sk] || 'N/A', sk)

              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'view_columns') {
            let view_columns_values = Object.values(changed_meta.view_columns)
            let view_columns_old_values = Object.values(old_meta.view_columns)

            view_columns_old_values = view_columns_old_values.filter(vc => vc !== null)

            let n = view_columns_values.length
              ? view_columns_values.toString().replaceAll(',', ', ')
              : 'N/A'
            let o = view_columns_old_values.length
              ? view_columns_old_values.toString().replaceAll(',', ', ')
              : 'N/A'

            let obj: any = {
              key: 'View Columns',
              old: clear(o || ''),
              new: clear(n || ''),
            }
            let can_add = await this.removeRepeats(obj)
            if (can_add) add(obj)
          }

          if (meta_key === 'agency') {
            let agency_keys = Object.keys(changed_meta.agency)

            for await (const ak of agency_keys) {
              let name = `agency ${clear(ak)}`
              let o = await this.checkData(old_meta.agency[ak] || 'N/A', ak)
              let n = await this.checkData(changed_meta.agency[ak] || 'N/A', ak)

              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }
        } else {
          let obj = {
            key: clear(meta_key),
            old: clear(old_meta[meta_key] || 'N/A'),
            new: clear(changed_meta[meta_key] || 'N/A'),
          }
          let can_add = await this.removeRepeats(obj)
          if (can_add) add(obj)
        }
      }
    }

    for (const key of keys) {
      if (diff_values[key] !== old_values[key]) {
        let updated_value = diff_values[key]
        let old_value: any = old_values[key]

        if (json_columns.includes(key)) {
          if (old_value !== null) {
            old_value = JSON.parse(old_value)
          } else {
            old_value = 'N/A'
          }

          if (updated_value !== null) {
            updated_value = JSON.parse(updated_value)
          } else {
            updated_value = 'N/A'
          }

          if (updated_value !== 'N/A') {
            let kyz = Object.keys(updated_value)
            for (const ky of kyz) {
              if (key === 'targetting') {
                // all values are arrays here
                let val = updated_value[ky]
                let old_val = old_value[ky]

                let o = await this.checkData(old_val || 'N/A', ky)
                let n = await this.checkData(val || 'N/A', ky)

                if (ky == 'time_restrictions') {
                  // here we are treating individual time restrictions inside targetting

                  if (n !== 'N/A' || n.length) {
                    if (this.detectArrayType(n) === 'array.object') {
                      n.map(async (element, idx) => {
                        let time_res_keys = Object.keys(element)

                        for (const trk of time_res_keys) {
                          if (element[trk] === null) {
                            delete element[trk]
                          } else {
                            let obj = {
                              key: clear(`${key} ${ky} ${idx + 1} ${trk}`),
                              old: o[idx] && o[idx][trk] ? o[idx][trk] : 'N/A',
                              new: element[trk] ? element[trk] : 'N/A',
                              date: element,
                            }
                            let can_add = await this.removeRepeats(obj)
                            if (can_add) add(obj)
                          }
                        }
                      })
                    }
                  }
                } else {
                  let obj = {
                    key: clear(`${key} ${ky}`),
                    old: clear(o.length ? o : 'N/A'),
                    new: clear(n.length ? n : 'N/A'),
                  }
                  let can_add = await this.removeRepeats(obj)
                  if (can_add) add(obj)
                }
              } else if (key === 'metadata') {
                // metadata must be extracted alone because of nested objects
                if (!Object.keys(old_values.metadata || {}).length) {
                  old_values.metadata = this.nullObj(
                    JSON.parse(JSON.stringify(diff_values.metadata)),
                  )
                }

                await extract(old_values.metadata, updated_value.metadata)
              } else if (key === 'tracking_events') {
                let old = JSON.parse(old_values.tracking_events)
                let newVal = JSON.parse(diff_values.tracking_events)

                newVal.forEach((te, tIdx) => {
                  let teKeys = Object.keys(te)

                  teKeys.map(async k => {
                    let obj = {
                      key: clear(`${key} ${k} list ${tIdx + 1}`),
                      old: clear(
                        old && old.length && old[tIdx] && old[tIdx][k] ? old[tIdx][k] : 'N/A',
                      ),
                      new: clear(te[k] ?? 'N/A'),
                    }

                    let can_add = await this.removeRepeats(obj)
                    if (can_add) add(obj)
                  })
                })
              } else if (key === 'frequency_caps') {
                let old = JSON.parse(old_values.frequency_caps)
                let newVal = JSON.parse(diff_values.frequency_caps)

                newVal.forEach((te, tIdx) => {
                  let teKeys = Object.keys(te)

                  teKeys.map(async k => {
                    let obj = {
                      key: clear(`${key} ${tIdx + 1} ${k}`),
                      old: clear(
                        old && old.length && old[tIdx] && old[tIdx][k] ? old[tIdx][k] : 'N/A',
                      ),
                      new: clear(te[k] ?? 'N/A'),
                    }

                    let can_add = await this.removeRepeats(obj)
                    if (can_add) add(obj)
                  })
                })
              }
            }
          }
        } else {
          let o = await this.checkData(old_value || 'N/A', key)
          let n = await this.checkData(updated_value || 'N/A', key)

          if (
            (o === '[]' && n === 'N/A')
            || (n === '[]' && o === 'N/A')
            || (n === '{}' && o === 'N/A')
            || (n === 'N/A' && o === '{}')
            || (n === '[]' && o === '[]')
          ) {
            o = []
            n = []
          } else {
            let obj: any = {
              key: clear(key),
              old: clear(o || ''),
              new: clear(n || ''),
              type: 'string',
            }

            let can_add = await this.removeRepeats(obj)
            if (can_add) add(obj)
          }
        }
      }
    }
    return diff
  }

  /**
   * This function is the core for checking all the diffrence between changes in the invoice.
   */
  public async old_checkDiff(old_values: any, new_values: any) {
    let diff: any = []
    let diff_values = this.difference(old_values, new_values)

    let add = (d: any) => diff.push(d)
    let clear = (s: any) => {
      if (isString(s)) {
        return s?.replaceAll('_', ' ')?.replaceAll('id', '')?.replace('00:00:00', '') || s
      }
      return s
      //
    }
    /**
     * Use only with the metadata object
     *
     * Here we loop the metadata object keys
     *
     * Create condition for formating any property inside of metadata object
     */
    let extract = async (old_meta: any, changed_meta: any) => {
      let chng_keys = Object.keys(changed_meta)
      let meta_key: any = null
      for await (meta_key of chng_keys) {
        if (isObject(changed_meta[meta_key])) {
          if (meta_key === 'client_data') {
            let client_data_keys = Object.keys(changed_meta[meta_key])

            for await (const cdk of client_data_keys) {
              let name = meta_key.replace('_data', ` ${cdk}`)

              let obj: any = {
                key: clear(name),
                old: !old_meta.client_data.hasOwnProperty(cdk)
                  ? 'N/A'
                  : old_meta[meta_key][cdk]
                    ? old_meta[meta_key][cdk]
                    : 'N/A',
                new: changed_meta[meta_key][cdk] ? changed_meta[meta_key][cdk] : 'N/A',
              }

              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'custom_billing') {
            let custom_billing_keys = Object.keys(changed_meta.custom_billing)

            for await (const cbk of custom_billing_keys) {
              let name = `${clear(meta_key)} ${cbk.replace('billing_', ' ')}`
              let obj: any = {
                key: name,
                old: old_meta.custom_billing[cbk] ? old_meta.custom_billing[cbk] : 'N/A',
                new: changed_meta.custom_billing[cbk] ? changed_meta.custom_billing[cbk] : 'N/A',
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'header') {
            let header_keys = Object.keys(changed_meta.header)
            for await (const hk of header_keys) {
              let name = clear(hk)
              let o = await this.checkData(old_meta.header[hk] || 'N/A', hk)
              let n = await this.checkData(changed_meta.header[hk] || 'N/A', hk)
              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'payee') {
            let payee_keys = Object.keys(changed_meta.payee)

            for await (const pk of payee_keys) {
              let name = `Payee ${clear(pk)}`
              let o = await this.checkData(old_meta.payee[pk] || 'N/A', pk)
              let n = await this.checkData(changed_meta.payee[pk] || 'N/A', pk)

              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'station') {
            let station_keys = Object.keys(changed_meta.station)

            for await (const sk of station_keys) {
              let name = `station ${clear(sk)}`
              let o = await this.checkData(old_meta.station[sk] || 'N/A', sk)
              let n = await this.checkData(changed_meta.station[sk] || 'N/A', sk)

              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'view_columns') {
            let view_columns_values = Object.values(changed_meta.view_columns)
            let view_columns_old_values = Object.values(old_meta.view_columns)

            view_columns_old_values = view_columns_old_values.filter(vc => vc !== null)

            let n = view_columns_values.length
              ? view_columns_values.toString().replaceAll(',', ', ')
              : 'N/A'
            let o = view_columns_old_values.length
              ? view_columns_old_values.toString().replaceAll(',', ', ')
              : 'N/A'

            let obj: any = {
              key: 'View Columns',
              old: clear(o || ''),
              new: clear(n || ''),
            }
            let can_add = await this.removeRepeats(obj)
            if (can_add) add(obj)
          }

          if (meta_key === 'agency') {
            let agency_keys = Object.keys(changed_meta.agency)

            for await (const ak of agency_keys) {
              let name = `agency ${clear(ak)}`
              let o = await this.checkData(old_meta.agency[ak] || 'N/A', ak)
              let n = await this.checkData(changed_meta.agency[ak] || 'N/A', ak)

              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }
        } else {
          let obj = {
            key: clear(meta_key),
            old: clear(old_meta[meta_key] || 'N/A'),
            new: clear(changed_meta[meta_key] || 'N/A'),
          }
          let can_add = await this.removeRepeats(obj)
          if (can_add) add(obj)
        }
      }
    }

    let arrayExtract = async (old: any, changed: any, key_name: any = null) => {
      // extracts the array values
      changed.map(async (item: any, i: number) => {
        let old_property = old[i] ? old[i] : 'N/A'
        if (key_name === 'tracking_events') {
          let obj: any = {
            key: `${clear(key_name)}-${i + 1}`,
            old: old_property,
            new: item || 'N/A',
          }
          let can_add = await this.removeRepeats(obj)
          if (can_add) add(obj)
        }
      })
    }

    let targettingExtract = async (old: any, changed: any, key_name: any = null) => {
      let changed_keys = Object.keys(changed)

      for await (const k of changed_keys) {
        let obj: any = {
          key: `${clear(key_name)} ${clear(k)}`,
          old: old[k] ? old[k] : 'N/A',
          new: changed[k] ? changed[k] : 'N/A',
        }

        let can_add = await this.removeRepeats(obj)
        if (can_add) add(obj)
      }
    }

    let ignore_keys = ['id']
    let keys = Object.keys(diff_values)

    for await (const k of keys) {
      if (
        diff_values[k] !== old_values[k]
        && !['metadata', 'tracking_events', 'targetting'].includes(k)
      ) {
        if (!ignore_keys.includes(k)) {
          let o = await this.checkData(old_values[k] || 'N/A', k)
          let n = await this.checkData(new_values[k] || 'N/A', k)

          let obj = {
            key: clear(k),
            old: clear(o || ''),
            new: clear(n || ''),
          }
          let can_add = await this.removeRepeats(obj)
          if (can_add) add(obj)
        }
      }

      if (k === 'tracking_events') {
        await arrayExtract(
          JSON.parse(old_values.tracking_events),
          JSON.parse(diff_values.tracking_events),
          'tracking_events',
        )
      }

      if (k === 'targetting') {
        // metadata must be extracted alone because of nested objects
        if (!Object.keys(old_values.targetting || {}).length) {
          old_values.targetting = this.nullObj(JSON.parse(JSON.stringify(diff_values.targetting)))
        }

        await targettingExtract(
          JSON.parse(old_values.targetting),
          JSON.parse(diff_values.targetting),
          'targetting',
        )
      }

      if (k === 'metadata') {
        // metadata must be extracted alone because of nested objects
        if (!Object.keys(old_values.metadata || {}).length) {
          old_values.metadata = this.nullObj(JSON.parse(JSON.stringify(diff_values.metadata)))
        }

        await extract(old_values.metadata, diff_values.metadata)
      }
    }

    return diff
  }

  /**
   * Removes repeated values that probably wasnt changed in the audit old_values to new_values
   * @param obj
   */
  public async removeRepeats(obj: any) {
    return new Promise((res, rej) => {
      if (isString(obj.old) && isString(obj.new)) {
        if (obj.old.toLowerCase() === obj.new.toLowerCase()) {
          res(false)
        }
      }
      if (obj.old === obj.new || isEqual(obj.old, obj.new)) {
        res(false)
      }

      res(true)
    })
  }

  /**
   * Format the payload in the given conditions
   *
   * This is where you want to create conditions, format the data before consuming it in the table.
   *
   * @param data Current value
   * @param key Current key from the object
   */
  public async checkData(data: any, key: any) {
    // TODO: Add more conditions

    if (data === 'N/A') return data
    let users_table_check = ['sales_rep_id', 'account_manager_id']
    let check_numbers = [
      'due',
      'total',
      'tax',
      'net_subtotal',
      'gross_subtotal',
      'net_total',
      'gross_total',
      'net_rate',
      'gross_rate',
      'cost_total',
      'cost_rate',
    ]
    let json_check = ['tracking_events']

    let check_date = ['due_at', 'broadcast_month', 'created_at', 'updated_at']

    if (users_table_check.includes(key)) {
      if (check_numbers.includes(key)) {
        // Format currency
        let is_num = !isNaN(data)
        if (is_num) {
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            Number(data),
          )
        }
      }

      return data
    }

    if (check_date.includes(key)) {
      // formate dates
      return moment(data).format('MM/DD/YYYY')
    }

    return data
  }

  /**
   * Set all properties to null
   *
   * This is used when the old_values from invoice is empty
   *
   * @param obj
   */
  public nullObj(obj: any): any {
    return (
      Object.keys(obj).forEach(
        k => (obj[k] = obj[k] === Object(obj[k]) ? this.nullObj(obj[k]) : null),
      ),
      obj
    )
  }

  /**
   *  ref: https://davidwells.io/snippets/get-difference-between-two-objects-javascript
   *
   *  This function will return the changes in the given payload
   *
   *  @param origObj Is the old_values
   *  @param newObj  Is the new_values
   *
   */
  public difference(origObj: any, newObj: any) {
    const changes = (newObj: any, origObj: any) => {
      let arrayIndexCounter = 0
      return transform(newObj, (result: any, value: any, key: any) => {
        if (!isEqual(value, origObj[key])) {
          let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
          result[resultKey] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value
        }
      })
    }
    return changes(newObj, origObj)
  }
}
