import Api from '@/models/Api'
import Model from './interface/Model'

export default class Issue extends Model {
  public id: null | string = null

  public message: null | string = null

  public level: null | string = null

  public dismissed: boolean = false

  public static dismissIssue(id: any) {
    const api = new Api()
    return api.post(`issues/${id}`, { dismissed: 1 })
  }
}
