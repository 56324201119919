
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import CreativesTable from '@/pages/Order/components/CreativesTable.vue'
import AssociatedCreative from '@/models/AssociatedCreative'

@Component({
  components: {
    CreativesTable,
  },
})
export default class LineItemCreative extends ViewModel {
  @Ref() public creative_table!: any

  @Prop({ default: () => [] })
  public LineItem!: any

  @Prop({ default: 'line-item' })
  public mode!: string

  public local_creatives: AssociatedCreative[] = []

  @Watch('local_creatives')
  public onLocalCreatives() {
    this.$emit('update:LineItem', {
      ...this.LineItem,
      associated_creatives: this.local_creatives,
    })
  }
}
