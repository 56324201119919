
import { Component, Prop, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import lineitem_options from '@/pages/LineItem/line-items-options'
import FormInput from '../FormInput/FormInput.vue'
import SelectPicker from '../SelectPicker/SelectPicker.vue'
import IconAction from '../IconAction/IconAction.vue'

@Component({
  components: {
    FormInput,
    SelectPicker,
    IconAction,
  },
})
export default class FrequencyCaps extends ViewModel {
  @Prop()
  public value!: any

  @Prop({ default: false })
  public disabled!: boolean

  public get local_value() {
    return this.value
  }

  public set local_value(value: any) {
    this.$emit('input', value)
  }

  public get line_item_options() {
    return lineitem_options
  }

  public addFrequency() {
    this.local_value.frequency_caps.push({
      id: this.randomUUID(),
      impressions: 1,
      limit: 1,
      period: 'week',
    })
  }

  public removeFrequency(index: any) {
    this.local_value.frequency_caps.splice(index, 1)
  }
}
